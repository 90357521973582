import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import MenuList from "./MenuList";
import { getUserRole, isLogsCloudWatch } from "../../../utils/utils";

class AsideLeft extends React.Component {
  asideMenuRef = React.createRef();

  render() {
    const {
      userRole,
      cloudWatchUser,
      location: { pathname }
    } = this.props;

    const menu = [
      // {
      //   title: "Dashboard",
      //   root: true,
      //   icon: "flaticon2-architecture-and-city",
      //   page: "dashboard",
      //   hashpage: "dashboard",
      //   translate: "MENU.DASHBOARD",
      //   bullet: "dot"
      // },
      {
        title: "Data",
        icon: "flaticon2-expand",
        page: "data",
        hashpage: "data",
      },
      {
        title: "User",
        root: true,
        icon: "flaticon-users-1",
        page: "user",
        hashpage: "user",
      },
      {
        title: "Gropus",
        root: true,
        icon: "flaticon2-group",
        page: "groups",
        hashpage: "groups",
      },
      {
        title: "Settings",
        root: true,
        icon: "flaticon-cogwheel-2",
        page: "config",
        hashpage: "config",
      },
      {
        title: "Help",
        root: true,
        icon: "flaticon-interface-11",
        page: "decoument",
        hashpage: "decoument",
      },
    ];

    const dsmenu = [
      // {
      //   title: "Dashboard",
      //   root: true,
      //   icon: "flaticon2-architecture-and-city",
      //   page: "dashboard",
      //   translate: "MENU.DASHBOARD",
      //   bullet: "dot"
      // },
      {
        title: "Data",
        root: true,
        icon: "flaticon2-expand",
        page: "data",
        hashpage: "data"
      },
      {
        title: "Settings",
        root: true,
        icon: "flaticon-cogwheel-2",
        page: "config",
        hashpage: "config"
      },
      {
        title: "Help",
        root: true,
        icon: "flaticon-interface-11",
        page: "decoument",
        hashpage: "decoument"
      }
    ];

    const superAdminMenu = [
      {
        title: "Subscribers",
        root: true,
        icon: "flaticon-users-1",
        page: "subscribers",
        hashpage: "subscribers"
      },
      {
        title: "Help",
        root: true,
        icon: "flaticon-interface-11",
        page: "decoument",
        hashpage: "decoument"
      }
    ]

    return (
      <>
        <div
          id="inf_aside_menu"
          ref={this.asideMenuRef}
          style={{ maxHeight: "90vh", position: "relative" }}
          onMouseEnter={this.mouseEnter}
          onMouseLeave={this.mouseLeave}
          data-ktmenu-vertical="1"
          className="inf-aside-menu inf-aside-menu--dropdown"
          // {...asideMenuAttr}
        >
          {/* {this.props.disableAsideSelfDisplay && (
            <div className="inf-header-logo">
              <Link to="">
                <img alt="logo" src={this.props.headerLogo} />
              </Link>
            </div>
          )} */}
          <ul className="inf-menu__nav">
            {cloudWatchUser ? "" : 
              userRole === "Administrator" ?  
              <MenuList
                currentUrl={pathname}
                menuConfig={menu}
              /> : userRole === "SuperAdmin" ? <MenuList
                currentUrl={pathname}
                menuConfig={superAdminMenu}
              /> : <MenuList
                currentUrl={pathname}
                menuConfig={dsmenu}
              /> 
            }
          </ul>
        </div>
      </>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const user = state.user;
  const userRole = getUserRole(user);
  const cloudWatchUser = isLogsCloudWatch();
  return {user, userRole, cloudWatchUser}
}

export default withRouter(connect(mapStateToProps, null)(AsideLeft))
