import React from "react";
import { connect } from "react-redux";
import { Input, Form, Radio, Select, Row, Col } from 'antd';

const { Option } = Select;
const { TextArea } = Input;

class AddBucketForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      accessType: props.bucket.accessKeyId ? "access_by_key" :  props.bucket.accessKeyId ? "access_by_role" : "",
      cloud: props.bucket.cloud,
    }
  }
  setType = _type => {
    this.setState({ accessType: _type });
  };
  
  render() {
    const { accessType,cloud } = this.state;
    const { bucket } = this.props;

    const onChangeAccessType = (e) => {
      this.setType(e.target.value)
    };

    const onChangeCloud = (value) => {
      this.setState({ cloud: value });
    };

    return (
      <>
        <Form ref={this.props.innerRef} layout='vertical' initialValues={bucket}>
          <Row>
            <Col span={12}>
              <Form.Item label="Bucket Name" className="form-lable" name='bucketname' 
                rules={[{required: true, message: '' }, bucket.bucketname ? {} : { validator: this.props.nameValidator } ]}>
                <Input placeholder="Bucketname" autoFocus disabled={bucket.bucketname ? true : false}/>
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '10px'}}>
              <Form.Item name='cloud' label='Storage provider' className="form-lable" rules={[{required: true }]}>
                <Select placeholder="Select storage provider" onChange={onChangeCloud}>
                  <Option key="aws" value="aws"> AWS </Option>
                  <Option key="azure" value="azure"> Azure </Option>
                  <Option key="minio" value="minio"> Minio </Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item label="Endpoint Url" className="form-lable" name='endpoint' rules={[]}>
                <Input placeholder="endpoint" />
              </Form.Item>
            </Col>
            <Col span={12} style={{paddingLeft: '10px'}}>
              <Form.Item label="Region" className="form-lable" name='region' rules={[]}>
                <Input placeholder="region" />
              </Form.Item>
            </Col>
          </Row>
          {
            cloud === 'minio' ? <> 
              <Form.Item label="Shared Secret" className="form-lable" name='sharedSecret' rules={[]}>
                <TextArea rows={3} placeholder="sharedSecret" />
              </Form.Item>
            </> : <> 
            <Form.Item>
              <Radio.Group onChange= {onChangeAccessType} >
                <Radio value="access_by_key"> <span className="radio-span"> Access Key/Secret Access Key </span> </Radio>
                <Radio value="access_by_role"> <span className="radio-span"> IAM Role/External ID</span> </Radio> 
              </Radio.Group>
            </Form.Item>
            {accessType === "access_by_key" &&(
              <Row>
                <Col span={12}>
                  <Form.Item label="Access Key" className="form-lable" name='accessKeyId' rules={[]}>
                    <Input placeholder="accessKeyId" />
                  </Form.Item>
                </Col>
                <Col span={12} style={{paddingLeft: '10px'}}>
                  <Form.Item label="Secret AccessKey" className="form-lable" name='secretAccessKey' rules={[]}>
                    <Input placeholder="secretAccessKey" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            {accessType === "access_by_role" &&(
              <Row>
                <Col span={12}>
                  <Form.Item label="IAM Role" className="form-lable" name='accessRole' rules={[]}>
                    <Input placeholder="accessRole" />
                  </Form.Item>
                </Col>
                <Col span={12} style={{paddingLeft: '10px'}}>
                  <Form.Item label="Role external id" className="form-lable" name='accessRoleExt' rules={[]}>
                    <Input placeholder="accessRoleExt" />
                  </Form.Item>
                </Col>
              </Row>
            )}
            </>
          }
          <Form.Item label="Description" className="form-lable" name='description'>
            <TextArea rows={3} placeholder="description" />
          </Form.Item>
        </Form>
      </>
    );
  }
}

export default connect(null, null)(AddBucketForm);