import React from "react";
import { connect } from "react-redux";
import AnimateLoading from "../AnimateLoading";
import UserProfile from './Topbar/UserProfile';
import KTHeader from "./utils/header";
import { toAbsoluteUrl } from "../../utils/utils";

class Header extends React.Component {
  headerCommonRef = React.createRef();


  componentDidMount() {
    let options = {};
    if (
      this.headerCommonRef.current.getAttribute("data-ktheader-minimize") ===
      "1"
    ) {
      options["minimize.desktop.on"] = "inf-header--minimize";
      options["offset.desktop"] = 130;
    }
    new KTHeader(this.headerCommonRef.current, options);
  }

  render() {
    return (
      <div
        className="inf-header inf-grid__item inf-header--fixed"
        id="inf-header"
        ref={this.headerCommonRef}
      >
        <AnimateLoading />
        <img alt="logo" className="header-logo" src={toAbsoluteUrl("/img/InfinStorBaseLogo_Black.png")} />
        <div className="inf-header__topbar">
          <UserProfile showAvatar={false} showHi={true} showBadge={true} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  store:store,
});

export default connect(mapStateToProps)(Header);
