import React, { Component } from 'react';
import { connect } from 'react-redux';
import  Spinner  from './Spinner';
import { getApis } from '../store/reducers/api.reducer';
import { isTokenExpired } from '../utils/utils';
import cogoToast from 'cogo-toast';
import ErrorPage from '../error-pages/ErrorPage';
export class RequestStateWrapper extends Component {
  static defaultProps = {
    requests: [],
  };

  state = {
    shouldRender: false,
    shouldRenderError: false,
  };

  static getErrorRequests(requests) {
    return requests.filter((r) => {
      if (r.error) {
        return true;
      }
      return false;
    });
  }

  static getDerivedStateFromProps(nextProps) {
    const shouldRender = nextProps.requests.length
      ? nextProps.requests.every((r) => r && r.active === false)
      : true;

    const requestErrors = RequestStateWrapper.getErrorRequests(
      nextProps.requests
    );

    return {
      shouldRender,
      shouldRenderError: requestErrors.length > 0,
      requestErrors,
    };
  }

  render() {
    const { children } = this.props;
    const { shouldRender, shouldRenderError } = this.state;
    if(shouldRender && !shouldRenderError){
      return <div> {children} </div>;
    } else if(shouldRender && shouldRenderError) {
      const errorMessage = isTokenExpired() ? "Session expired." : "Something went wrong, Please try again.." 
      cogoToast.error(errorMessage);
      return <ErrorPage /> ;
    } else {
      return <Spinner />;
    }
  }
}

const mapStateToProps = (state, ownProps) => ({
  requests: getApis(ownProps.requestIds, state)
});

export default connect(mapStateToProps)(RequestStateWrapper);
