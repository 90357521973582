import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../utils/utils";

class Brand extends React.Component {
  render() {
    return (
      <div
        className={`inf-aside__brand inf-grid__item`}
        id="inf_aside_brand"
      >
        <div className="inf-aside__brand-logo">
          <Link to="">
            <img alt="logo" src={toAbsoluteUrl("/img/InfinStorSymbol_White.png")} />
          </Link>
        </div>
      </div>
    );
  }
}

export default Brand;
