import { getJson, postJson } from "../utils/AxiosUtils";
const serviceConfig = window.ServiceConfig;
export class Service {
  // cwsearch api
  // static complete = (data) =>
  //   getJson({ relativeUrl: 'https://' + window.CwsearchVdbServer + '.' + window.CwsearchService + '/Prod/1.0/cwsearchvdb/complete', data });

  static getCustomerInfo = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/customerinfo', data });
      
  static createGroup = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/create_group', data });

  static updateGroup = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/update_group', data });

  static getGroup = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/get_group', data });

  static deleteGroup = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/delete_group', data });

  static listCognitoGroup = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/list_groups', data });

  static listGroupMembers = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/list_users_in_group', data });

  static addUserToGroup = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/add_user_to_group', data });

  static removeUserFromGroup = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/remove_user_from_group', data });

  static listSecondaryUsers = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/listsecondaryusers', data });
  
  static browseBucketByTime = (path, data) =>
    getJson({ relativeUrl: "https://" + serviceConfig.ApiServer + "/webhdfs/v1" + path, data });

  static lookupOneFileByTime = (path, data) =>
    getJson({ relativeUrl: "https://" + serviceConfig.ApiServer + "/webhdfs/v1" + path, data });
  
  static addSecondaryUser = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/addsecondaryuser', data });

  static toggleInfinsnap = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/toggleinfinsnap', data });
  
  static setExtractmetadata = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/setextractmetadata', data });
    
  static add_mod_delete_bucket = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/add-mod-del-bucket', data });

  static addBucketAuth = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/add-bucket-auth', data });

  static removeBucketAuth = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/remove-bucket-auth', data });
    
  static changeSecondaryUserStatus = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/changesecondaryuserstatus', data });

  static createUserAccessKey = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/createkey', data });

  static deleteUserAccessKey = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/deletekey', data });

  static createCustomToken = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/create_custom_token', data });

  static revokeAccessToken = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/revoke_access_token', data });

  static changeArtifactURI = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/custarturi', data });

  static setUsepresignedurl = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/setusepresignedurl', data });
    
  static newSubscriber = (data) => 
    postJson({ relativeUrl: 'https://'+ serviceConfig.ApiServer +'/newsubscriber', data });
  //superAdmin
  static listSubscribers = (data) =>
    postJson({ relativeUrl: 'https://'+ serviceConfig.MlflowServer +'/api/2.0/mlflow/infinstor/list_subscribers', data });
}
