import React, { Component } from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { TextField } from "@material-ui/core";
import { Link, Redirect } from "react-router-dom";
import { injectIntl } from "react-intl";
import * as auth from "../store/reducers/auth.reducer";
import { CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";

class ForgotPassword extends Component {
  state = { isRequested: false };

  render() {
    const { intl, setUserAttributes } = this.props;
    const { isRequested } = this.state;
    const serviceConfig = window.ServiceConfig;

    if (isRequested) {
      return <Redirect to="/auth/codeVerify" />;
    }

    const redirect = () => {
      this.setState({ isRequested: true });
    }
    return (
      <div className="inf-login__body">
        <div className="inf-login__form">
          <div className="inf-login__title">
            <img
              className="inf-login-logo-default"
              alt="logo"
              src={"/img/InfinStorBaseLogo_Black.png"}
            />
          </div>

          <Formik
              initialValues={{ username: "" }}
              validate={values => {
                const errors = {};

                if (!values.username) {
                  errors.username = intl.formatMessage({
                    id: "AUTH.VALIDATION.REQUIRED_FIELD"
                  });
                }

                return errors;
              }}
              onSubmit={(values, { setStatus, setSubmitting }) => {

                let data = {
                  UserPoolId : serviceConfig.UserPoolId,
                  ClientId : serviceConfig.ClientId 
                };
                let userPool = new CognitoUserPool(data);
                let cognitoUser = new CognitoUser({
                  Username: values.username,
                  Pool: userPool
                });
                cognitoUser.forgotPassword({
                  onSuccess: function (result) {
                    const { CodeDeliveryDetails } = result;
                    setUserAttributes({cognitoUser, CodeDeliveryDetails});
                    redirect()
                  },
                  onFailure: function(err) {
                    setSubmitting(false);
                    setStatus(
                      intl.formatMessage(
                        { id: "AUTH.VALIDATION.NOT_FOUND" },
                        { name: values.username }
                      )
                    );
                  },
                });
              }}
          >
            {({
                values,
                status,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
              }) => (
                <form onSubmit={handleSubmit} className="inf-form">
                  {status && (
                      <div role="alert" className="alert alert-danger">
                        <div className="alert-text">{status}</div>
                      </div>
                  )}

                  <div className="form-group">
                    <TextField
                        type="username"
                        label="UserName"
                        margin="normal"
                        fullWidth={true}
                        name="username"
                        onBlur={handleBlur}
                        onChange={handleChange}
                        value={values.username}
                        helperText={touched.username && errors.username}
                        error={Boolean(touched.username && errors.username)}
                    />
                  </div>
                  <div className="inf-login__actions">
                    <Link to="/auth">
                      <button
                          type="button"
                          className="btn btn-secondary btn-elevate inf-login__btn-secondary"
                      >
                        Back
                      </button>
                    </Link>

                    <button
                        type="submit"
                        className="btn btn-primary btn-elevate inf-login__btn-primary"
                        disabled={isSubmitting}
                    >
                      Submit
                    </button>
                  </div>
                  <div className ="copyright">
                    © Copyright 2023 InfinStor, Inc. All Rights Reserved.
                  </div>
                </form>
            )}
          </Formik>
        </div>
      </div>
    );
  }
}

export default injectIntl(connect(null, auth.actions)(ForgotPassword));
