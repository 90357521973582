import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputModal } from "../layout/InputModal";
import { BucketAuthorizationForm } from './BucketAuthorizationForm';
import { addBucketAuthApi, customerInfoApi } from '../store/actions';

export class BucketAuthorizationModalmpl extends Component {
  handleAddBucketAuth = async (data) => {
    const { bucket } = this.props;
    var postData = `bucketname=${bucket.bucketname}&cloud=${bucket.cloud}&principal=${data.principal}&type=${data.type}&role=${data.role}`;
    await this.props.addBucketAuthApi(postData).then((re) => {
      if(re.value && re.value.startsWith('Successfully performed')){
        this.props.customerInfoApi();
      } else {
        // TODO handle Error 
      }
    }).catch(e=> {
      // TODO handle Error 
    });
  };

  render() {
    const { isOpen, title, okText, auth } = this.props;
    return (
      <InputModal
        title={title}
        okText={okText}
        isOpen={isOpen}
        handleSubmit={this.handleAddBucketAuth}
        onClose={this.props.onClose}
      >
        <BucketAuthorizationForm auth={auth} edit={title=== "Edit Bucket Authorization" ? true : false}/>
      </InputModal>
    );
  }
}

const mapDispatchToProps = {
  addBucketAuthApi,
  customerInfoApi
};

export const BucketAuthorizationModal = connect(null, mapDispatchToProps)(BucketAuthorizationModalmpl);
