import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { getFileContentApi } from "../../store/actions";
import { Skeleton } from "antd";
import { Table } from 'antd';

function DataPreview(props) {
  const {s3_url } = props;
  const [fileLoading, setFileLoading ] = useState(false);
  const [tableData, setTableData] = useState({
    dataSource: [],
    columns:[]
  });

  const process = (dataString) => {
    const data = dataString.split(/\n/).map(function(lineStr) {
      return lineStr.split(",");
    });

    const header = data[0];
    const lines = data.slice(1);
    const dataColumns = header.map((he) => {
      return {
        title: he,
        dataIndex: he,
        key: he,
      };
    });

    const dataSource = lines.map((line, index) => {
      const lineData = { "key": index };
      line.map((data, index) => {
        const head = header[index];
        lineData[head] = data;
        }
      );
      return lineData;
    });

    
    return {dataSource: dataSource, columns: dataColumns};
  }

  useEffect(() => {
    if(s3_url) {
      setFileLoading(true);
      props.getFileContentApi(s3_url, false).then((result)=> {
        setTableData(process(result.value))
        setFileLoading(false);
      })
    }
  }, [s3_url]);

  return (
    <>
      {fileLoading ? <Skeleton /> : <>
        <Table dataSource={tableData.dataSource} columns={tableData.columns} />;
      </>
      }
    </>
  );
}

const mapDispatchToProps = {
  getFileContentApi,
};

export default connect(null, mapDispatchToProps)(DataPreview);