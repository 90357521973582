import React from "react";
import I18nProvider from "./i18n/I18nProvider";
import AppRoot from "./app/AppRoot";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

export default function App({ store, persistor, basename }) {
  return (
    <Provider store={store} loading={<div> loading </div>}>
      <PersistGate persistor={persistor}>
        <React.Suspense fallback={<div> loading </div>}>
          <I18nProvider>
            <AppRoot />
          </I18nProvider>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
