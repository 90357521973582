import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../store/reducers/auth.reducer";

function Registration(props) {
  const { intl } = props;

  return (
    <div className="inf-login__body">
      <div className="inf-login__form">
        <div className="inf-login__title">
          <img
              className="inf-login-logo-default"
              alt="logo"
              src={"/img/InfinStorBaseLogo_Black.png"}
            />
        </div>
        <Formik
          initialValues={{
            password: "",
            confirmPassword: ""
          }}
          validate={values => {
            const errors = {};
            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (!/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/i.test(values.password)) {
              errors.password = 'The password must be at least eight characters long and must contain a lowercase letter, an uppercase letter, a number, and a special character.';
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            const { userAttributes, cognitoUser } = props.userAttributes;
            delete userAttributes.email_verified;
            delete userAttributes.email;
            cognitoUser.completeNewPasswordChallenge(values.password, userAttributes, {
              onSuccess: async function (result) {                
                let idToken = result.idToken.jwtToken;
                let refreshToken = result.refreshToken.token;
                let accessToken = result.accessToken.jwtToken;
                let date = new Date();
                date.setTime(date.getTime()+(57*60*1000));
                const auth = {
                  idToken: idToken,
                  refreshToken: refreshToken,
                  accessToken: accessToken,
                  tokenTime: date.getTime()
                };                  
                props.login(auth);
                props.getUser({ Username:'', customerId:'', productCode:''}).then((ele) => {
                  props.setInitConfiguration();
                });
              },
              onFailure: function (err) {
                setSubmitting(false);
                setStatus(intl.formatMessage({
                  id: "AUTH.VALIDATION.INVALID_LOGIN"
                }))
              }
            });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              <div role="alert" className="alert alert-info" style={{margin: '12px'}}>
                <div className="alert-text">You need to set new password for confirm your Account. </div>
              </div>
              {status && (
                <div role="alert" className="alert alert-danger">
                  <div className="alert-text">{status}</div>
                </div>
              )}
              <div className="form-group mb-0">
                <TextField
                  type="password"
                  margin="normal"
                  label="Password"
                  className="inf-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label="Confirm Password"
                  className="inf-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
              </div>

              <div className="inf-login__actions">
                <Link to="/auth">
                  <button type="button" className="btn btn-secondary btn-elevate inf-login__btn-secondary">
                    Back
                  </button>
                </Link>

                <button
                  disabled={isSubmitting}
                  className="btn btn-primary btn-elevate inf-login__btn-primary"
                >
                  Submit
                </button>
              </div>
              <div className ="copyright">
                © Copyright 2023 InfinStor, Inc. All Rights Reserved.
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (store) => {
  const auth = store.auth;
  const {userAttributes} = auth;
  return {userAttributes}
}

export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(Registration)
);
