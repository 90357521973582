import React from "react";

export default function Spinner() {

  return (<>
    {/* <!-- begin:: Content --> */}
      <div id="spinner" className="inf-loading-spinner">
        <svg className="spinner" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2"></circle>
        </svg>
      </div> 
    {/* <!-- end:: Content --> */}
  </>)
}
