import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

class HeaderMobile extends React.Component {
  toggleButtonRef = React.createRef();

  render() {
    const { asideDisplay } = this.props;

    return (
      <div
        id="inf-header_mobile"
        className="inf-header-mobile kt-header-mobile--fixed"
      >
        <div className="inf-header-mobile__logo">
          <Link to="/">
            <img alt="logo" style={{ width: '15%'}} src="/img/InfinStorSymbol.png" />
          </Link>
        </div>
        <div className="inf-header-mobile__toolbar">
          {asideDisplay && (
            <button
              className="inf-header-mobile__toolbar-toggler inf-header-mobile__toolbar-toggler--left"
              id="inf-aside_mobile_toggler"
            >
              <span />
            </button>
          )}
          <button
            className="inf-header-mobile__toolbar-toggler"
            id="inf-header_mobile_toggler"
          >
            <span />
          </button>
          <button
            ref={this.toggleButtonRef}
            className="inf-header-mobile__toolbar-topbar-toggler"
            id="inf-header_mobile_topbar_toggler"
          >
            <i className="flaticon-more-1" />
          </button>
        </div>
      </div>
    );
  }
}

const mapStateToProps = store => ({
  asideDisplay: false,
  toggleOptions: {
    target: "body",
    targetState: "inf-header__topbar--mobile-on",
    togglerState: "inf-header-mobile__toolbar-topbar-toggler--active"
  }
});

export default connect(mapStateToProps)(HeaderMobile);
