import React, { useState } from "react";
import { Tab, Tabs } from "@material-ui/core";
import { connect } from "react-redux";
import { getUserRole } from "../../utils/utils";
import ConfigureAWS from "./ConfigureAWS";
import GeneralConfig from "./GeneralConfig";
import TokenPage from "./TokenPage";

function ConfigurationPage(props) {
  const [tab, setTab] = useState(0);
  const {userRole} = props;
  return(
    <>
      <div className="inf-portlet">
        <div className="inf-portlet__head">
          <Tabs
            component="div"
            value={tab}
            className="builder-tabs"
            onChange={(_, nextTab) => {
              setTab(nextTab);
            }}
          > 
            <Tab label="Manage Token" />
            {(userRole === "Administrator") && (<Tab label="Presigned URL" />)}
            {(userRole === "Administrator") && (<Tab label="Configure AWS" />)}
          </Tabs>
        </div>
        {tab === 0 && (
          <TokenPage />
        )}
        {tab === 1 && (
          <GeneralConfig />
        )}
        {tab === 2 && (
          <ConfigureAWS />
        )}
      </div>
    </>
  )   
}

const mapStateToProps = (state) => {
  const user = state.user;
  const userRole = getUserRole(user)
  return { userRole }
}

export default connect(mapStateToProps, null)(ConfigurationPage);