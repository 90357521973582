import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputModal } from "../layout/InputModal";
import AddBucketForm from './AddBucketForm';
import { bucketNameValidator } from '../utils/Validator';
import { addModDelBucketApi, customerInfoApi } from '../store/actions';
import { getPostData } from '../utils/utils';
import cogoToast from 'cogo-toast';

export class EditBucketModalmpl extends Component {
  handleEditBucket = async (data) => {
    data['op']= 'modify';
    await this.props.addModDelBucketApi(getPostData(data)).then(re => {
      if(re.value && re.value ==='Successfully performed modify op on bucket'){
        this.props.customerInfoApi();
      } else {
        cogoToast.error("Something went wrong, Please try again..");
      }
    }).catch(e=> {
      cogoToast.error("Something went wrong, Please try again..");
    });
  };

  render() {
    const { isOpen, bucket } = this.props;
    return (
      <InputModal
        title='Edit Bucket'
        okText='Save'
        isOpen={isOpen}
        handleSubmit={this.handleEditBucket}
        onClose={this.props.onClose}
      >
        <AddBucketForm nameValidator={bucketNameValidator} bucket={bucket} />
      </InputModal>
    );
  }
}

const mapDispatchToProps = {
  addModDelBucketApi,
  customerInfoApi
};

export const EditBucketModal = connect(null, mapDispatchToProps)(EditBucketModalmpl);
