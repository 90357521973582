import React, { useState } from "react";
import { Input, Switch, message } from 'antd'
import { connect } from "react-redux";
import { changeArtifactURIApi, setUsepresignedurlApi, customerInfoApi } from "../../store/actions";
import clsx from "clsx";

function GeneralConfig(props) {
  const [artifactPath, setArtifactPath] = useState();
  const [artifactPathError, setArtifactPathError] = useState('');
  const [artifactLoading, setArtifactLoading] = useState(false);
  const [infinPresignedLoader, setInfinPresignedLoader] = useState(false);
  const [mlflowPresignedLoader, setMlflowPresignedLoader] = useState(false);
  const { user, auth } = props;

  const saveArtifactURI = () => {
    setArtifactLoading(true);
    if(artifactPath && artifactPath.startsWith("s3://")) {
      var artifactBucket = artifactPath.split('/')[2];
      if((artifactBucket.length > 2)) {
        // TODO: Check if artifactBucket is infinbucket
        // const bucketnames = new Set(user['InfinSnapBuckets'].map((values) => values['bucketname']));
        // console.log('bucketnames', bucketnames);
        // if (bucketnames.has(artifactBucket)) {
        //       const data = {
        //         artifactUri:artifactPath,
        //         // Authorization: localStorage.getItem("idToken")
        //       }
        //       props.changeArtifactURIApi(data).then((result)=> {
        //         console.log('result', result);
        //       }).catch((e)=> {
        //         console.log('e', e);
        //       });
        // } else {
        //   setArtifactPathError(`Bucket ${artifactBucket} is not found. Add a bucket`);
        // }
        var postData = `artifactUri=${artifactPath}&Authorization=${auth.idToken}`;
        props.changeArtifactURIApi(postData).then((result)=> {
          if(result.value === "successfully saved customer Artifact URI") {
            props.customerInfoApi().then(r => {
              setArtifactLoading(false);
            });
            message.success(result.value);
          }
        }).catch((e)=> {
          message.error("Something went wrong, Please try again..");
        });
      } else {
        setArtifactPathError('Please enter valid Artifact URI value.');
      }
    } else {
      setArtifactPathError('Please enter valid Artifact URI value.');
    }
  }

  const togglePresignedUrlEnable = (usage, enable) => {
    let postData = "usePresignedUrlForInfinSnap=" + enable ;
    if(usage === 'infinsnap') {
      setInfinPresignedLoader(true);
    } else {
      postData = "usePresignedUrlForMLflow=" + enable ;
      setMlflowPresignedLoader(true);
    };
    props.setUsepresignedurlApi(postData).then((r) => {
      if(r.value === 'successfully saved usePresignedUrl') {
        message.success(r.value);
      } else {
        message.error("Something went wrong, Please try again..");
      }
      setInfinPresignedLoader(false);
      setMlflowPresignedLoader(false);
      props.customerInfoApi();
    }).catch(e => {
      setInfinPresignedLoader(false);
      setMlflowPresignedLoader(false);
      props.customerInfoApi();
      message.error("Something went wrong, Please try again..");
    });
  }

  return(
    <>
      <div className="inf-portlet__body">
        <div className="row row-no-padding row-col-separator-xl">
          <div className="col-xl-6">
            <div className="inf-widget1" style={{paddingLeft: '1px'}}>
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  <h3 className="inf-widget1__title">Presigned URL</h3>
                  <span className="inf-widget1__desc">Use Presigned URLs to manage access to InfinStor Enabled Buckets :
                  <Switch size="small" style={{marginLeft: '10px'}}
                    defaultChecked={user.usePresignedUrlForInfinSnap ==="true" ? true : false}
                    onChange={(checked)=> {togglePresignedUrlEnable('infinsnap', checked)}} 
                    loading={infinPresignedLoader} 
                  /> <br></br>
                  </span>
                </div>
              </div>
               <div className="inf-widget1__item ng-star-inserted">
                  <span className="inf-widget1__desc">Use Presigned URLs to manage access to MLflow Artifacts Buckets :
                  <Switch size="small" style={{marginLeft: '10px'}}
                    defaultChecked={user.usePresignedUrlForMLflow==="true" ? true : false}
                    onChange={(checked)=> {togglePresignedUrlEnable('mlflow', checked)}} 
                    loading={mlflowPresignedLoader} 
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="inf-widget1">
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  <h3 className="inf-widget1__title">Customer Artifact URI</h3>
                  <span className="inf-widget1__desc">Enter the Customer Artifact URI. It must contain a s3 bucketname..</span>
                </div>
              </div>

              <div className="inf-widget1__item ">
                <div className="inf-widget1__info" style={{width:'100%'}}>
                  <Input placeholder="Enter artifact path" defaultValue={user.customerArtifactUri} onChange={(event)=> {
                    setArtifactPathError('');
                    setArtifactPath(event.target.value)
                    if(event.target.value === "") {
                      setArtifactPathError('Artifact path is required.');
                    }
                  }}/>
                </div>
                <div style={{paddingLeft:'20px'}}>
                  <button className={`btn btn-sm btn-label-brand 
                    ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": artifactLoading})}`} 
                    onClick={saveArtifactURI}> Save 
                  </button>
                </div>
              </div>
              <span style={{fontSize: '12px'}}>(e.g) : s3://bucket-name/path/in/bucket</span> <br />
              <span className="error_text"> {artifactPathError} </span>
            </div>
          </div>
        </div>
      </div>
    </>
  )   
}

const mapStateToProps = (state) => {
  const {user, auth} = state;
  return { user, auth }
}

const mapDispatchToProps = {
  changeArtifactURIApi,
  setUsepresignedurlApi,
  customerInfoApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(GeneralConfig);

