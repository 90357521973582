import store from "../store/store";

export function removeCSSClass(ele, cls) {
  const reg = new RegExp("(\\s|^)" + cls + "(\\s|$)");
  ele.className = ele.className.replace(reg, " ");
}

export function addCSSClass(ele, cls) {
  ele.classList.add(cls);
}

export const toAbsoluteUrl = pathname => process.env.PUBLIC_URL + pathname;

export function setupAxios(axios, store, dispatch ) {
  axios.interceptors.request.use(
    async config => {
      const {
        auth: { idToken }
      } = store.getState();
      if (idToken) {
        config.headers.Authorization = `${idToken}`;
      }
      return config;
    },
    err => Promise.reject(err)
  );
}

/*  removeStorage: removes a key from localStorage and its sibling expiracy key
    params:
        key <string>     : localStorage key to remove
    returns:
        <boolean> : telling if operation succeeded
 */
export function removeStorage(key) {
  try {
    localStorage.setItem(key, "");
    localStorage.setItem(key + "_expiresIn", "");
  } catch (e) {
    console.log(
      "removeStorage: Error removing key [" +
        key +
        "] from localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

/*  getStorage: retrieves a key from localStorage previously set with setStorage().
    params:
        key <string> : localStorage key
    returns:
        <string> : value of localStorage key
        null : in case of expired key or failure
 */
export function getStorage(key) {
  const now = Date.now(); //epoch time, lets deal only with integer
  // set expiration for storage
  let expiresIn = localStorage.getItem(key + "_expiresIn");
  if (expiresIn === undefined || expiresIn === null) {
    expiresIn = 0;
  }

  expiresIn = Math.abs(expiresIn);
  if (expiresIn < now) {
    // Expired
    removeStorage(key);
    return null;
  } else {
    try {
      const value = localStorage.getItem(key);
      return value;
    } catch (e) {
      console.log(
        "getStorage: Error reading key [" +
          key +
          "] from localStorage: " +
          JSON.stringify(e)
      );
      return null;
    }
  }
}
/*  setStorage: writes a key into localStorage setting a expire time
    params:
        key <string>     : localStorage key
        value <string>   : localStorage value
        expires <number> : number of seconds from now to expire the key
    returns:
        <boolean> : telling if operation succeeded
 */
export function setStorage(key, value, expires) {
  if (expires === undefined || expires === null) {
    expires = 24 * 60 * 60; // default: seconds for 1 day
  }

  const now = Date.now(); //millisecs since epoch time, lets deal only with integer
  const schedule = now + expires * 1000;
  try {
    localStorage.setItem(key, value);
    localStorage.setItem(key + "_expiresIn", schedule);
  } catch (e) {
    console.log(
      "setStorage: Error setting key [" +
        key +
        "] in localStorage: " +
        JSON.stringify(e)
    );
    return false;
  }
  return true;
}

export const getUUID = () => {
  const randomPart = Math.random().toString(36).substring(2, 10);
  return new Date().getTime() + randomPart;
};

export const updateToken = async(refreshToken) => {
  const serviceConfig = window.ServiceConfig;

  if(refreshToken !== null) {
    var postData = "{\n"
    postData += "    \"AuthParameters\" : {\n"
    postData += "        \"REFRESH_TOKEN\" : \"" + refreshToken + "\"\n"
    postData += "    },\n"
    postData += "    \"AuthFlow\" : \"REFRESH_TOKEN_AUTH\",\n"
    postData += "    \"ClientId\" : \"" + serviceConfig.ClientId + "\"\n"
    postData += "}\n"
    let region = serviceConfig.UserPoolId.split('_')[0];
    var url = 'https://cognito-idp.'+ region +'.amazonaws.com:443/'
    var request = new XMLHttpRequest();
    
    request.open("POST", url, false);
    request.setRequestHeader("Content-Type", "application/x-amz-json-1.1");
    request.setRequestHeader("X-Amz-Target", "AWSCognitoIdentityProviderService.InitiateAuth");
    try {
      request.send(postData);
    } catch (error) {
      // TODO handle Error
      return false;
    }
    if (request.status === 200) {
      var resp = JSON.parse(request.responseText);
      let authResult = resp.AuthenticationResult;
      let date = new Date();
      date.setTime(date.getTime()+(57*60*1000));
      localStorage.setItem('tokenTime', date.getTime().toString());
      date.setTime(date.getTime()+(1000*60*1000));
      document.cookie = "mlflow-request-header-Authorization=" + authResult.IdToken + "; expires=" + date.toString();
      document.cookie = "Idtoken=" + authResult.IdToken + "; expires=" + date.toString();
      document.cookie = "aws-accessToken=" + authResult.AccessToken + "; expires=" + date.toString();
      const auth = {
        idToken: authResult.IdToken,
        refreshToken: refreshToken,
        accessToken: authResult.AccessToken,
        tokenTime: date.getTime()
      };                  
      store.dispatch({ type: "[Login] Action", payload: auth });
      return true;
    } else {
      return false;
    }
  }
};

// export const checkAuthorization = (user) => ( user.userName ? true : false );
export const checkAuthorization = (auth, user) => {
  if(auth.idToken) {
    if(auth.idToken.startsWith('Custom')) {
      return true;
    } else { 
      const expiry = (JSON.parse(atob(auth.idToken.split('.')[1]))).exp;
      const authorization = (Math.floor(new Date().getTime() / 1000)) <= expiry;
      return authorization ? true : false;
    }
  } else {
    return false;
  } 
};

export function accessCookie(cookieName) {
  var name = cookieName + "=";
  var allCookieArray = document.cookie.split(';');
  for(var i=0; i<allCookieArray.length; i++) {
    var temp = allCookieArray[i].trim();
    if (temp.indexOf(name)==0)
      return temp.substring(name.length,temp.length);
    }
  return "";
}

export function isLogsCloudWatch() {
  // TODO change cloudwatch product code
  const productCode = accessCookie("ProductCode")
  return productCode === "dfgfdgsa345435";
}


export function isTokenExpired() {
  const state = store.getState();
  const { auth } = state;

  if(auth.idToken) {
    if(auth.idToken.startsWith('Custom')) {
      return false;
    } else {
      const expiry = (JSON.parse(atob(auth.idToken.split('.')[1]))).exp;
      if((Math.floor(new Date().getTime() / 1000)) >= expiry) {
        setTimeout(() => {
          store.dispatch({ type: "[Logout] Action"});
        }, 500);
      }
      return (Math.floor(new Date().getTime() / 1000)) >= expiry;
    }
  } else {
    setTimeout(() => {
      store.dispatch({ type: "[Logout] Action"});
    }, 500);
    return true;
  }               
}

export function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export function getUserNamesAsSet() {
  const state = store.getState();
  const user = state.secondaryUser;
  return new Set(user.map((values) => values['username']));
}

export function getGroupNamesAsSet() {
  const state = store.getState();
  const groups = state.groups;
  return new Set(groups.map((values) => values['GroupName']));
}

export function getBucketNamesAsSet() {
  const state = store.getState();
  const {user} = state;
  return new Set(user.InfinSnapBuckets.map((values) => values['bucketname']));
}

export function getCustomerId() {
  const state = store.getState();
  const user = state.user;
  return user.customerId;
}

export function getGroupInfo(groups, group_name) {
  return groups[group_name] || {};
}

export function getGroupMembers(group) {
  if(group){
    return group['members'] || []
  } else {
    return []
  }
}



export const getUserRole = (userInfo) => {
  if(userInfo.isSuperAdmin && userInfo.isSuperAdmin === "true") {
    return 'SuperAdmin';
  }
  if(userInfo.isSecondaryUser && userInfo.isSecondaryUser === "false" ) {
    return 'Administrator';
  } 
  return 'DataScientist';
};

export const getUserBuckets = (userInfo) => {
  return userInfo.InfinSnapBuckets || [];
};

export const getBucketInfo= (userInfo, bucketname) => {
  const userBuckets = getUserBuckets(userInfo);
  const bucket = userBuckets.find((bucket) => bucket.bucketname === bucketname);
  return bucket;
};

export const getBucketAuthorization = (bucket) => {
  let authorizations = [];

  if(bucket && bucket.userRoles) {
    let parseData = JSON.parse(bucket.userRoles);
    let result = Object.keys(parseData)
               .map(key => ({principal: key, role: parseData[key], type: 'user' }));
    authorizations = authorizations.concat(result);
  }

  if(bucket && bucket.groupRoles) {
    let parseData = JSON.parse(bucket.groupRoles);
    let result = Object.keys(parseData)
               .map(key => ({principal: key, role: parseData[key], type: 'group' }));
    authorizations = authorizations.concat(result);
  }
  
  return authorizations;
};

export const getExtension = (path) => {
  const parts = path.split(/[./]/);
  return parts[parts.length - 1];
};

export const getPostData = (data) => {
  var postData = '';
  for (const [key, value] of Object.entries(data)) {
    if(postData === '') {
      postData = `${key}=${value||''}`
    } else {
      postData = `${postData}&${key}=${value||''}`
    }
  }
  return postData;
}

export const IMAGE_EXTENSIONS = new Set(['jpg', 'bmp', 'jpeg', 'png', 'gif', 'svg']);
export const TEXT_EXTENSIONS = new Set([
  'txt',
  'log',
  'err',
  'cfg',
  'conf',
  'cnf',
  'cf',
  'ini',
  'properties',
  'prop',
  'hocon',
  'toml',
  'yaml',
  'data',
  'yml',
  'xml',
  'json',
  'js',
  'py',
  'py3',
  'md',
  'rst',
  'jsonnet',
]);
export const HTML_EXTENSIONS = new Set(['html']);
export const PDF_EXTENSIONS = new Set(['pdf']);
export const DATA_EXTENSIONS = new Set(['csv', 'tsv']);

