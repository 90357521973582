import React from "react";
import { connect } from "react-redux";
import Menu from "./components/Menu";
import Brand from "../brand/Brand";
// import Menu from "./Menu";

class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  componentDidMount() {
    // eslint-disable-next-line
    
  }

  render() {
    return (
      <>
        <button className="inf-aside-close" id="inf_aside_close_btn">
          <i className="la la-close" />
        </button>
        <div
          id="inf-aside"
          ref={this.asideOffCanvasRef}
          className={`inf-aside ${this.props.asideClassesFromConfig} inf-grid__item inf-grid inf-grid--desktop inf-grid--hor-desktop`}
        >
          <Brand />
          <div
            id="inf_aside_menu_wrapper"
            className="inf-aside-menu-wrapper inf-grid__item inf-grid__item--fluid"
          >
            <Menu />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = store => ({
  disableAsideSelfDisplay: false,
  asideClassesFromConfig: 'inf-aside--fixed',
  menuCanvasOptions: {
    baseClass: "inf-aside",
    overlay: true,
    closeBy: "inf_aside_close_btn",
    toggleBy: {
      target: "inf_aside_mobile_toggler",
      state: "inf-header-mobile__toolbar-toggler--active"
    }
  }
});

export default connect(mapStateToProps)(AsideLeft);
