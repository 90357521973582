import React, { Component } from 'react';
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import { customerInfoApi } from './store/actions';
import { getUUID, getUserRole } from './utils/utils';
import Layout from "./layout/Layout";
import ContentViewer from './layout/ContentViewer';
import RequestStateWrapper from './layout/RequestStateWrapper';
import UserListPage from './users/UserListPage';
import BucketListPage from './buckets/BucketListPage';
import ConfigurationPage from './configuration/componets/ConfigurationPage';
import SubscriberListPage from './subscribers/SubscriberListPage';
import BrowseBucket from './buckets/BrowseBucket';
import ManageBucket from './buckets/ManageBucket';
import UserProfilePage from './users/UserProfilePage';
import GroupListPage from './users/groups/GroupListPage';
import GroupInfoPage from './users/groups/GroupInfoPage';
import ErrorPage from './error-pages/ErrorPage'
import { message } from 'antd';

class App extends Component {
  customerInfoApiId = getUUID();

  componentDidMount() {
    this.props.customerInfoApi(this.customerInfoApiId).then((re)=> {
      const value = re.value;
      if(!value.userName) {
        message.error("Invalid User");
        window.location.replace("/#/auth/login");
      }
    });
  }

  render() {
    const { userRole } = this.props;
    return (
      <>
        <RequestStateWrapper requestIds={[this.customerInfoApiId]}> 
          <Layout> 
            <ContentViewer> 
              <Switch>
                {
                  userRole === "SuperAdmin" ? [
                    <Redirect key="1" exact from="/" to="/subscribers" />,
                    <Redirect key="2" exact from="/auth/login" to="/subscribers" />,
                    <Redirect key="3" exact from="/auth/*" to="/subscribers" />
                  ] :  
                  [
                    <Redirect key="1" exact from="/" to="/data" />,
                    <Redirect key="1" exact from="/superadmin" to="/data" />,
                    <Redirect key="2" exact from="/auth/login" to="/data" />,
                    <Redirect key="3" exact from="/auth/*" to="/data" />
                  ]
                }
                {/* <Route exact path="/dashboard" component={Dashboard} /> */}
                <Route path="/data" component={BucketListPage} />
                <Route path="/browse/:bucketname" component={BrowseBucket} />
                <Route path="/manage/:bucketname" component={ManageBucket} />
                <Route path="/user" component={UserListPage} />
                <Route path="/groups" component={GroupListPage} />
                <Route path="/group/:groupname" component={GroupInfoPage} />
                <Route path="/subscribers" component={SubscriberListPage} />
                <Route path="/config" component={ConfigurationPage} />
                <Route path="/profile" component={UserProfilePage} />  
                <Route path="/error" component={ErrorPage} />  
              </Switch> 
            </ContentViewer>
          </Layout>
        </RequestStateWrapper>
      </>
    )
  }
}

const mapStateToProps = (state) => {
  const user = state.user;
  const userRole = getUserRole(user);
  return { user, userRole  }
}

const mapDispatchToProps = {
  customerInfoApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
