import React, {useState, useEffect} from "react";
import ReactPaginate from "react-paginate";
import { connect } from "react-redux";
import { getUserRole, isLogsCloudWatch } from "../utils/utils";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { Switch,Space } from 'antd';
import { AddBucketModal } from "./AddBucketModal";
import { setExtractmetadataApi, customerInfoApi } from "../store/actions";
import CWSearchCFTPage from "../configuration/componets/CWSearchCFTPage";
import cogoToast from 'cogo-toast';

function BucketListPage(props) {
  const userInfo = props.user;
  const {userRole, history, cloudWatchUser} = props;
  const userBuckets = userInfo.InfinSnapBuckets || [];
  const [extractMetLoadings, setExtractMetLoadings] = useState([]);
  const [openAddBucketModal, setAddBucketModal] = useState(false);
  const [filterBuckets, setfilterBuckets] = useState(userInfo.InfinSnapBuckets || []);
  const [searchText, setSearchText] = useState('');

  //pagination
  const [page, setPage] = useState(0);
  const [filterData, setFilterData] = useState([]);
  const numberOfResult = 10;

  useEffect(() => {
    if(filterBuckets.length > numberOfResult ) {
      setFilterData(
        filterBuckets.filter((item, index) => {
          return (index >= page * numberOfResult) & (index < (page + 1) * numberOfResult);
        })
      );
    } else {
      setFilterData(filterBuckets);
    }
  }, [page, filterBuckets]);

  const closeAddBucketModal = () =>{
    setAddBucketModal(false);
  }

  const filterBucket = (searchValue) => {
    const lowerCasedSearchValue = searchValue.toLowerCase();
    return lowerCasedSearchValue === ''
      ? setfilterBuckets(userBuckets)
      : setfilterBuckets(userBuckets.filter(({ bucketname }) => bucketname.toLowerCase().includes(lowerCasedSearchValue)));
  };

  const refreshBucketData = (userInfo) => {
    const allBuckets = userInfo.InfinSnapBuckets || [];
    const lowerCasedSearchValue = searchText.toLowerCase();
    const fil_bucket = lowerCasedSearchValue === ''
      ? allBuckets
      : allBuckets.filter(({ bucketname }) => bucketname.toLowerCase().includes(lowerCasedSearchValue));
    if(fil_bucket.length > numberOfResult ) {
      setFilterData(
        fil_bucket.filter((item, index) => {
          return (index >= page * numberOfResult) & (index < (page + 1) * numberOfResult);
        })
      );
    } else {
      setFilterData(fil_bucket);
    }
  }

  const setExtractmetadata = (bucket, checked, index) => {
    setExtractMetLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    var postData = "bucketname=" + bucket + "&extractMetadata=" + checked ;
    props.setExtractmetadataApi(postData).then(request => {
      setExtractMetLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
      props.customerInfoApi().then(r=> {
        refreshBucketData(r.value);
        cogoToast.success('Action completed successfully.');
      });
    }).catch((error) => {
      setExtractMetLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
      refreshBucketData(userInfo);
      cogoToast.error("Something went wrong, Please try again..");
    });
  }

  return (
    <>
      {cloudWatchUser ? <div className="inf-portlet"> <CWSearchCFTPage /> </div>  : 
        <>
          <div className="inf-portlet">
            <div className="inf-widget1" style={{padding: '15px'}}>
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  {userBuckets.length > 0 &&(
                    <div className={"input-group"}>
                      <div className="input-group-prepend">
                        <span className="input-group-text inf-search__input_group_text">
                          <i className="flaticon2-search-1" />
                        </span>
                      </div>
                      <input
                        type="text"
                        autoFocus={true}
                        placeholder="Search..."
                        onChange={(event)=> {filterBucket(event.target.value); setSearchText(event.target.value)}}
                        className="form-control inf-search__input"
                      />
                    </div>
                  )}
                </div>
                {(userRole === "Administrator") && (
                  <button className="btn btn-sm btn-label-brand" onClick={(event)=> { console.log(event);setAddBucketModal(true) }}> Add Bucket </button>
                )}
              </div>
            </div>
          </div>
            {(userBuckets.length !== 0 && filterBuckets.length === 0) &&(
              <div className="inf-noresult">
                <div>
                  <div className="flaticon2-warning inf-noresult-icon"> </div>
                  <span className='inf-noresult-text'> oh, No result found.. </span>
                </div>          
              </div>
            )}

            {userBuckets.length === 0 &&(
              <div className="inf-noresult">
                <div className="alert border border-danger" id="bucket_alert">  
                  <div className="flaticon2-warning inf-noresult-icon" style={{fontSize:'2rem'}}> </div>
                  <span style={{padding:'5px', fontWeight:'400'}}> 
                    {userRole === "Administrator"? "No buckets have been configured for InfinSnap. Please add buckets and enable infinsnap on buckets."
                    :"No buckets have been configured for InfinSnap." } 
                  </span>
                </div>
              </div>
            )}
          <div className="row">
            {
              filterData.map((bucket, index) => (
                <React.Fragment key={index}>
                  <div className="col-xl-6">
                    <div className="inf-portlet">
                      <div className="inf-portlet__body">
                        <div className="inf-widget4">
                          <div className="inf-widget4__item ">
                            <div className="inf-widget4__info ">
                              <span className="inf-widget4__username">{bucket.bucketname}</span>
                              <p className="inf-widget4__text "> <span className="inf-widget4__username">cloud:</span>  {bucket.cloud} </p>
                            </div>
                            <Link to={`/manage/${bucket.bucketname}`}>
                              <button className="btn btn-sm btn-label-dark">View</button>
                            </Link>  
                          </div>
                          <div className="inf-widget4__item ">
                            <span className="inf-bucket__desc"> <span className="font-weight-bolder font-size-sm">Description : </span> {bucket.description}</span>
                          </div>

                          <div className="d-flex align-items-center flex-wrap">
                            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                              <span className="mr-4">
                                <i className="flaticon-web icon-2x text-muted font-weight-bold"></i>
                              </span>
                              <div className="d-flex flex-column text-dark-75">
                                <span className="font-weight-bolder font-size-sm">Objects</span>
                                <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.objects}</span>
                              </div>
                            </div>
                        
                            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                              <span className="mr-4">
                                <i className="flaticon-interface-1 icon-2x text-muted font-weight-bold"></i>
                              </span>
                              <div className="d-flex flex-column text-dark-75">
                                <span className="font-weight-bolder font-size-sm">Size</span>
                                <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.size} bytes</span>
                              </div>
                            </div>
                        
                            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                              <span className="mr-4">
                                <i className="flaticon-pie-chart icon-2x text-muted font-weight-bold"></i>
                              </span>
                              <div className="d-flex flex-column text-dark-75">
                                <span className="font-weight-bolder font-size-sm">Extract Metadata</span>
                                <div className="d-flex ">
                                  <Space> 
                                    <span className={`font-weight-bolder font-size-h5 btn btn-xsm 
                                      ${clsx({"btn-label-success": (bucket.extractMetadata==="true")})}
                                      ${clsx({"btn-label-danger": (bucket.extractMetadata==="false")})}`}>
                                        {bucket.extractMetadata==="false" ? "Disabled ":" Enabled "} 
                                    </span>
                                    {(userRole === "Administrator") && (
                                      <Switch size="small" 
                                        defaultChecked={bucket.extractMetadata==="true" ? true : false}
                                        onChange={(checked)=> {setExtractmetadata(bucket.bucketname, checked, index)}} 
                                        loading={extractMetLoadings[index]} 
                                      />
                                    )}
                                  </Space>
                                </div>
                              </div>
                            </div>
                        
                            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                              <span className="mr-4">
                                <i className="flaticon-infinity icon-2x text-muted font-weight-bold"></i>
                              </span>
                              <div className="d-flex flex-column flex-lg-fill">
                                <span className="text-dark-75 font-weight-bolder font-size-sm">InfinSnap</span>
                                <span className={`btn btn-xsm 
                                  ${clsx({"btn-label-success": (bucket.infinSnapState==="Active")})}
                                  ${clsx({"btn-label-danger": (bucket.infinSnapState==="Inactive")})}
                                  ${clsx({"btn-label-warning": (bucket.infinSnapState==="Paused")})}`}>
                                    {bucket.infinSnapState}
                                </span> 
                                {/* <a href="#" className="text-primary font-weight-bolder">{bucket.infinSnapState}</a> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))
            }
            {filterBuckets.length > numberOfResult  &&(
              <div className='container'>
                <ReactPaginate
                  containerClassName={"page"}
                  activeClassName={"active"}
                  pageClassName={"page__numbers"}
                  onPageChange={(event) => setPage(event.selected)}
                  breakLabel="..."
                  pageCount={Math.ceil(filterBuckets.length / numberOfResult)}
                  previousLabel="<"
                  nextLabel=">"
                />  
              </div>
            )}
          </div>
          <AddBucketModal isOpen={openAddBucketModal} onClose={closeAddBucketModal} history={history}> </AddBucketModal>
        </>
      }
    </>
  );
}

const mapStateToProps = (state) => {
  const user = state.user;
  const userRole = getUserRole(user);
  const cloudWatchUser = isLogsCloudWatch();
  console.log('state', state);
  return { user, userRole, cloudWatchUser }
}

const mapDispatchToProps = {
  setExtractmetadataApi,
  customerInfoApi
}

export default connect(mapStateToProps, mapDispatchToProps)(BucketListPage);