import React, { useState } from "react";
import CodeBlock from "./CodeBlock";
import clsx from "clsx";
import { Input, message } from 'antd'
import { connect } from "react-redux";
import { createCustomTokenApi, revokeAccessTokenApi } from "../../store/actions";
import { CognitoUserPool, CognitoUser, CognitoRefreshToken } from "amazon-cognito-identity-js";

const { TextArea } = Input;

function TokenPage(props) {
  const [downloadTokenLoading, setdownloadTokenLoading] = useState(false);
  const [revokeTokenLoading, setRevokeTokenLoading] = useState(false);
  const [showScript, setShowScript] = useState(false);
  const [refreshToken, setRefreshToken] = useState();
  const [refreshTokenError, setRefreshTokenError] = useState('');
  const [scriptLoading, setscriptLoading] = useState(false);
  const [customToken, setCustomToken] = useState('');
  const serviceConfig = window.ServiceConfig;

  const code =`
    #!/bin/bash
    set -eux
    PACKAGE=infinstor_mlflow_plugin
    pip install --upgrade $PACKAGE
    mkdir -p /root/.infinstor
    set +x
    echo 'Token=${customToken}' > /root/.infinstor/token
    set -x
    mkdir -p /root/.ipython/profile_default/startup
    echo 'import os' > /root/.ipython/profile_default/startup/00-set-mlflow-env.py
    echo "os.environ['MLFLOW_TRACKING_URI'] = 'infinstor://${serviceConfig &&(serviceConfig.MlflowServer)}/'" >> /root/.ipython/profile_default/startup/00-set-mlflow-env.py
  `

  const downloadToken = () => {
    setdownloadTokenLoading(true);
    const { user, auth } = props;
    var token = new CognitoRefreshToken({ RefreshToken: auth.refreshToken });
    const poolData = {
      UserPoolId : serviceConfig.UserPoolId,
      ClientId : serviceConfig.ClientId
    };
    const userPool = new CognitoUserPool(poolData);
    const userData = {
      Username: user.userName,
      Pool: userPool
    };
    const cognitoUser = new CognitoUser(userData);
    cognitoUser.refreshSession(token, function (err, session) {
      if(err) {
        message.error(err.message)
        setdownloadTokenLoading(false);
        return;
      } 
      var accessToken = session.getAccessToken().getJwtToken();
      var tokenTime = new Date().getTime() + (50*60*1000);
      tokenTime = Math.floor(tokenTime/1000);
      var tokenData = "Token=" + accessToken + "\r\n" +
      "RefreshToken=" + session.refreshToken.token + "\r\n" +
      "ClientId=" + serviceConfig.ClientId + "\r\n" +
      "TokenTimeEpochSeconds=" + tokenTime + "\r\n" +
      "IdToken=" + session.getIdToken().getJwtToken() + "\r\n"+ 
      "Service=" + serviceConfig.Service + "\r\n";
      var element = document.createElement('a');
      element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(tokenData));
      element.setAttribute('download', 'token');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      setdownloadTokenLoading(false)
      return null;
    });
  }

  const revokeToken = () => {
    var tokenRegex = /[a-zA-Z0-9\-_]+?\.[a-zA-Z0-9\-_]+?\.([a-zA-Z0-9\-_]+)$/
    var regexResult = tokenRegex.exec(refreshToken);
    if (!regexResult) {
      setRefreshTokenError('Invalid token. Please enter valid token.');
    } else {
      setRevokeTokenLoading(true);
      var postData = "{ \"token\":";
          postData += "\"" + refreshToken + "\"";
          postData += "," + "\"client_id\":";
          postData += "\"" + serviceConfig.ClientId + "\"}";
      props.revokeAccessTokenApi(postData).then((result)=> {
        message.success('Sucessfully removed the token access.');
        setRefreshToken('');
        setRevokeTokenLoading(false);
      }).catch(err => {
        message.error("Something went wrong, Please try again..");
        setRevokeTokenLoading(false);
      });
    }
  }
  
  const getLoginScript = () => {
    setscriptLoading(true);
    var data = {action: "createToken"};
    props.createCustomTokenApi(data).then((result)=> {
      const value = result.value;
      setCustomToken(value.infinstor_token);
      setscriptLoading(false);
      setShowScript(true);
    }).catch((e)=> {
      message.error("Something went wrong, Please try again..");
      setscriptLoading(false);
      setShowScript(false);
    });
  }

  return(
    <>
      <div className="inf-portlet__body">
        <div className="inf-widget1" style={{padding: '1px', paddingRight:'25px'}}>
          <div className="inf-widget1__item ng-star-inserted">
            <div className="inf-widget1__info">
              <h3 className="inf-widget1__title">Generate Token file.</h3>
              {/* TODO: Add text for generate token */}
              {/* <span className="inf-widget1__desc">Let use this token in our clinet application authentication directly..</span> */}
            </div>
            <button className={`btn btn-sm btn-label-brand 
              ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": downloadTokenLoading})}`} 
              onClick={downloadToken}> Download Token 
            </button>
          </div>
        </div>
      </div>
      <div className="inf-portlet__body" style={{borderTop: "10px solid #f2f3f8"}}>
        <div className="row row-no-padding row-col-separator-xl">
          <div className="col-xl-6">
            <div className="inf-widget1" style={{paddingLeft: '1px'}}>
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  <h3 className="inf-widget1__title">Revoke Token Access</h3>
                  <span className="inf-widget1__desc">Enter the refresh Token in the text area to revoke the token access. Once revoke the token action is completed. You couldn't to use this token further.</span>
                </div>
              </div>
              <div className="inf-widget1__item ng-star-inserted">
              <TextArea onChange={(event)=> {
                  setRefreshTokenError('');
                  setRefreshToken(event.target.value)}}
                placeholder="Enter Refresh Token"
                autoSize={{
                  minRows: 2,
                  maxRows: 6,
                }}
              />
              <div style={{paddingLeft:'20px'}}>
                <button className={`btn btn-sm btn-label-brand 
                  ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": revokeTokenLoading})}`} 
                  onClick={revokeToken}> Revoke Token
                </button>
              </div>
              </div>
              <span className="error_text"> {refreshTokenError} </span>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="inf-widget1">
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  <h3 className="inf-widget1__title"> AWS SageMaker Studio Lifecycle Script</h3>
                </div>
              </div>
              <div className="inf-widget1__item ng-star-inserted">
                <span className="inf-widget1__desc">Show AWS SageMaker Studio Lifecycle Script.</span>
                {showScript? <button className="btn btn-sm btn-label-brand" onClick={async () => { setShowScript(false)}}> Hide </button> 
                  : <button className={`btn btn-sm btn-label-brand 
                    ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": scriptLoading})}`} 
                    onClick={getLoginScript}> Show Script
                  </button>
                }
              </div>
              {showScript && (
                <div className="inf-widget1__item ng-star-inserted">
                  <CodeBlock code={code} language={'bash'} ></CodeBlock>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )   
}

const mapStateToProps = (state) => {
  const {user, auth} = state;
  return { user, auth }
}

const mapDispatchToProps = {
  createCustomTokenApi,
  revokeAccessTokenApi
};

export default connect(mapStateToProps, mapDispatchToProps)(TokenPage);

