
import React, { Component } from "react";
import { connect } from "react-redux";
import * as auth from "../store/reducers/auth.reducer";

class ErrorPage extends Component {
  render() {   
    return (
      <>
        <div className="inf-grid__item inf-grid__item--fluid inf-grid inf-error-v1">
          <div className="inf-error-v1__container">
            <h1 className="inf-error-v1__number">ERROR</h1>
            <p className="inf-error-v1__desc">OOPS! Something went wrong here. go to <span className="text-primary font-weight-bolder" style={{cursor: 'pointer'}} onClick={()=> { this.props.logout();}}> Home </span> </p> 
             
          </div>
        </div>
      </>
    );
  }
}

export default connect(null, auth.actions)(ErrorPage);
