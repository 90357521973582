import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { getFileContentApi } from "../../store/actions";
import { Skeleton } from "antd";
import { toAbsoluteUrl } from "../../utils/utils";
import { Document, Page, pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = toAbsoluteUrl("/pdf.worker.js");

function PdfPreview(props) {
  const {s3_url} = props;
  const [fileLoading, setFileLoading ] = useState(false);
  const [numberofPages, setNumberOfPages] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfData, setPdfData] = useState();

  function onDocumentLoadSuccess({ pages }) {
    setNumberOfPages(pages);
  }

  useEffect(() => {
    if(s3_url) {
      setFileLoading(true);
      props.getFileContentApi(s3_url, true).then((result)=> {
        setPdfData(result.value);
        setFileLoading(false);
      })
    }
  }, [s3_url]);

  return (
    <>
      {fileLoading ? <Skeleton /> : 
        <div className='document'>
          <Document file={pdfData} onLoadSuccess={onDocumentLoadSuccess}>
            <Page pageNumber={currentPage} />
          </Document>
          <p>
            Page {currentPage} of {numberofPages}
          </p>
        </div>
      }
    </>
  );
}

const mapDispatchToProps = {
  getFileContentApi,
};

export default connect(null, mapDispatchToProps)(PdfPreview);