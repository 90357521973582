import React, { useState } from "react";
import { connect } from "react-redux";
import dayjs from 'dayjs';
import { Tree, DatePicker } from 'antd';
import { browseBucketByTimeApi, lookupOneFileByTimeApi, getFileContentApi, } from "../store/actions";
import { Radio } from "@material-ui/core";
import FilePreview from "./FilePreview";

const { RangePicker } = DatePicker;
const { DirectoryTree } = Tree;

function BrowseBucket(props) {
  const { bucket } = props;
  const [browseType, setBrowseType] = useState('infinsnap');
  const [selectedFile, setSelectedFile] = useState({});
  const [snapshotTime, setSnapShotTime ] = useState(dayjs().set('second', 0).valueOf());
  const [sliceStartTime, setSliceStartTime ] = useState(dayjs().set('hour', 0).set('minute', 0).set('second', 0).valueOf());
  const [sliceEndTime, setSliceEndTime ] = useState(dayjs().set('second', 0).valueOf());
  const [treeData, setTreeData ] = useState([
    {
      "title": bucket.bucketname ||'demo',
      "key": '0',
      "bucketname": bucket.bucketname ||'demo',
      "path":"/",
    }
  ]);

  if(bucket) {
    const onLoadData = (data) => new Promise((resolve) => {
      const browseData = {
        op: "LISTSTATUS_BATCH",
        startAfter: null,
        bucket: data.bucketname,
        path: data.path
      }
      if(browseType === 'infinslice'){
        browseData.time = sliceEndTime;
        browseData.timeBegin = sliceStartTime;
      } else if(browseType === 'infinsnap') {
        browseData.time =snapshotTime;
      } else {
        browseData.time = dayjs().set('second', 0).valueOf();
      }

      let rawdata = [];
      props.browseBucketByTimeApi(data.path, browseData).then(res => {
        const resultData = res.value;
        if (resultData.hasOwnProperty('DirectoryListing') &&
            resultData.DirectoryListing.hasOwnProperty('partialListing') &&
            resultData.DirectoryListing.partialListing.hasOwnProperty('FileStatuses') &&
            resultData.DirectoryListing.partialListing.FileStatuses.hasOwnProperty('FileStatus')) 
          {
            let files = resultData.DirectoryListing.partialListing.FileStatuses.FileStatus;
            if (files && files.length > 0) {
              for (var ind = 0; ind < files.length; ind++) {
                var file = files[ind];
                var typ = file.type;
                if (typ.localeCompare("DIRECTORY") === 0) {
                  // Directory 
                  rawdata.push({
                    "title": file.pathSuffix ,
                    "key": `${data.key}-${ind}`,
                    "bucketname": data.bucketname,
                    "path": data.path + file.pathSuffix +"/",
                  });
                } else {
                  // file
                  rawdata.push({
                    "title": file.pathSuffix,
                    "key": `${data.key}-${ind}`,
                    "bucketname": data.bucketname,
                    "path": data.path + file.pathSuffix,
                    "isLeaf": true,
                  });
                }
              } 
            } else {
              rawdata.push({
                "title": "Directory Empty",
                "key": `${data.key}-0`,
                "bucketname": data.bucketname,
                "path": data.path,
                "isLeaf": true,
                "icon": <i className="flaticon2-cancel-music" style={{fontSize: '10px'}}></i>,
                "disabled": true
              });
            }
            setTreeData(updateTreeData(treeData, data.key, rawdata))
          }
          resolve();
      }).catch((error) => {
        rawdata.push({
          "title": "Failed to load data..",
          "key": `${data.key}-0`,
          "bucketname": data.bucketname,
          "path": data.path,
          "isLeaf": true,
          "icon": <i className="flaticon2-cancel-music" style={{fontSize: '10px',color: 'red'}}></i>,
          "disabled": true
        });
        setTreeData(updateTreeData(treeData, data.key, rawdata))
        resolve();
      });
    });

    const updateTreeData = (list, key, children) =>
    list.map((node) => {
      if (node.key === key) {
        return {
          ...node,
          children,
        };
      }
      if (node.children) {
        return {
          ...node,
          children: updateTreeData(node.children, key, children),
        };
      }
      return node;
    });


    const onBrowseTypeChange = (e) => {
      setBrowseType(e.target.value);
      setTreeData([
        {
          "title": bucket.bucketname,
          "key": new Date().getTime(),
          "bucketname": bucket.bucketname,
          "path":"/",
        }
      ]);
    };

    const snapTimeChange = (value, dateString) => {
      setSnapShotTime(value.valueOf());
      setTreeData([
        {
          "title": bucket.bucketname,
          "key": new Date().getTime(),
          "bucketname": bucket.bucketname,
          "path":"/",
        }
      ]);
    };

    const sliceTimeChange = (value, dateString) => {
      setSliceStartTime(value[0].valueOf());
      setSliceEndTime(value[1].valueOf());
      setTreeData([
        {
          "title": bucket.bucketname,
          "key": new Date().getTime(),
          "bucketname": bucket.bucketname,
          "path":"/",
        }
      ]);
    };

    const onSelect = (selectedKeys, info) => {
      const sel_file = info.node;
      if(sel_file.isLeaf) {
        if(browseType === 'infinslice'){
          sel_file.time = sliceEndTime;
          sel_file.timeBegin = sliceStartTime;
        } else if(browseType === 'infinsnap') {
          sel_file.time =snapshotTime;
        } else {
          sel_file.time = dayjs().set('second', 0).valueOf();
        }
        setSelectedFile(sel_file);
      }
    };

    return (
      <>
        <div className="row row-no-padding row-col-separator-xl">
          <div className="col-xl-4">
          <div className="inf-portlet inf-portlet--height-fluid">
              <div className="inf-portlet__body">
                {(bucket.infinSnapState === "Active") && (
                  <div className="inf-widget4">
                    <div className="inf-widget4__item ">
                      <div>
                        <Radio
                          checked={browseType === "infinsnap"}
                          onChange={onBrowseTypeChange}
                          value="infinsnap"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "A" }}
                        />
                        <span className="inf-bucket__desc"> Infinsnap </span>

                        <Radio
                          color="secondary"
                          checked={browseType === "infinslice"}
                          onChange={onBrowseTypeChange}
                          value="infinslice"
                          name="radio-button-demo"
                          inputProps={{ "aria-label": "B" }}
                        />
                        <span className="inf-bucket__desc"> Infinslice </span>
                      </div>
                    </div>

                    <div className="inf-widget4__item ">
                      {browseType === "infinslice" ? 
                        <div className="inf-widget4__item ">
                          <span className="inf-bucket__desc">Select browse time range.</span>
                          <RangePicker 
                            onChange={sliceTimeChange}
                            defaultValue={[dayjs(sliceStartTime), dayjs(sliceEndTime)]}
                            showTime={{
                              format: 'HH:mm:ss',
                            }}
                            format="YYYY-MM-DD HH:mm:ss"
                          />
                        </div> : 
                        <div className="inf-widget4__item ">
                          <span className="inf-bucket__desc">Select browse time.</span>
                          <DatePicker showTime defaultValue={dayjs(snapshotTime)} onChange={snapTimeChange} placeholder ="infinsnap Time" format="YYYY-MM-DD HH:mm:ss" />
                        </div>
                      }
                    </div>
                    <div className="inf-widget4__item "></div>                 
                  </div>
                )}
                <div className="inf-widget4">
                  <div className="inf-widget4__item ">
                    <div className="d-flex align-items-center flex-wrap">
                      <DirectoryTree loadData={onLoadData} treeData={treeData} onSelect={onSelect} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-8">
            <FilePreview bucket={bucket} selectedFile={selectedFile}/>
          </div>
        </div>
      </>
    );
  } else {
    return(<div> Bucket not available </div>)
  }
}

const mapDispatchToProps = {
  browseBucketByTimeApi,
  lookupOneFileByTimeApi,
  getFileContentApi,
};

export default connect(null, mapDispatchToProps)(BrowseBucket);