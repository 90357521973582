import React, { useState } from "react";
import { connect } from "react-redux";
import { getUserRole, getBucketInfo, getBucketAuthorization } from "../utils/utils";
import { Table, Space, Popconfirm  } from 'antd';
import cogoToast from 'cogo-toast';
import clsx from "clsx";
import { 
  browseBucketByTimeApi, 
  lookupOneFileByTimeApi, 
  getFileContentApi, 
  addModDelBucketApi, 
  customerInfoApi, 
  toggleInfinsnapApi,
  removeBucketAuthApi } from "../store/actions";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { EditBucketModal } from "./EditBucketModal";
import { BucketAuthorizationModal } from "./BucketAuthorizationModal";
import BrowseBucket from "./BrowseBucket";

function ManageBucket(props) {
  const {userRole, bucket, authorization} = props;
  const [okText, setOkText] = useState('Add');
  const [modelTitle, setModelTitle] = useState('Add Bucket Authorization');
  const [auth, setAuth] = useState({});
  const [openEditBucketModal, setEditBucketModal] = useState(false);
  const [openBucketAuthModal, setBucketAuthModal] = useState(false);

  const closeEditBucketModal = () =>{
    setEditBucketModal(false);
  }

  const closeBucketAuthModal = () =>{
    setBucketAuthModal(false);
  }

  if(bucket) {
     
    const columns = [
      {
        title: 'Type (user/group)',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Name (user/group)',
        dataIndex: 'principal',
        key: 'principal',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Action',
        key: 'action',
        render: (auth) => (
          <>
            <span className="mr-4">
              <i className="flaticon2-edit inf-table-icon icon-1x font-weight-bold" onClick={() => {editBucketAuth(auth)}}></i>
            </span>
            <span className="mr-4">
              <Popconfirm
                title= 'Delete Authorization'
                description={"Are you sure you want to delete this authorization"}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                onConfirm={()=> deleteBucketAuth(auth)}
              >
                <i className="flaticon2-trash inf-table-icon icon-1x font-weight-bold"></i>
              </Popconfirm>
            </span>
          </>
        )
      },
    ];

    const deleteBucket = () =>
      new Promise((resolve, reject) => {
        var postData = "op=delete&bucketname=" + bucket.bucketname;
        props.addModDelBucketApi(postData).then(re => {
          if(re.value && re.value ==='Delete bucket') {
            props.customerInfoApi().then(r=> {
              resolve();
              props.history.push('/data');
            });
          } else {
            // TODO handle Error 
          }
        }).catch(e=> {
          // TODO handle Error 
          reject(e);
        });
      });

    const editBucketAuth = (auth) => {
      setOkText('Save');
      setAuth(auth);
      setModelTitle('Edit Bucket Authorization');
      setBucketAuthModal(true);
    }

    const deleteBucketAuth = (auth) =>  new Promise((resolve, reject) => {
      var postData = `bucketname=${bucket.bucketname}&cloud=${bucket.cloud}&principal=${auth.principal}&type=${auth.type}`;
      props.removeBucketAuthApi(postData).then(re => {
        if(re.value && re.value.startsWith('Successfully performed')){
          props.customerInfoApi().then((r) => {
            resolve();
          });
        } else {
          // TODO handle Error 
        }        
      }).catch(e=> {
        // TODO handle Error 
        reject(e);
      });
    });

    const addBucketAuth = () => {
      setOkText('Add');
      setAuth({});
      setModelTitle('Add Bucket Authorization');
      setBucketAuthModal(true);
    }

    const toggleInfinsnap = (action) => new Promise((resolve, reject) => {
      var postData = "bucketname=" + bucket.bucketname + "&action=" + action;
      props.toggleInfinsnapApi(postData).then(re => {
        if(re.value && re.value.startsWith("Successfully")) {
          props.customerInfoApi().then(r=> {
            cogoToast.success(re.value);
            resolve();
          });
        } else {
          cogoToast.error(re.value);
          resolve();
        }
      }).catch(e=> {
        cogoToast.error("Something went wrong, Please try again..");
        reject(e);
      });
    });

    return (
      <> 
        {(userRole === "Administrator") && (
          <div className="row">
            <div className="col-xl-6">
              <div className="inf-portlet inf-portlet--height-fluid">
                <div className="inf-portlet__body">
                  <div className="inf-widget4">
                    <div className="inf-widget4__item ">
                      <div className="inf-widget4__info ">
                        <span className="inf-widget4__username">{bucket.bucketname}</span>
                      </div>
                      <Space >
                        {bucket.infinSnapState === "Active" &&(
                          <Popconfirm
                            title= 'Pause Infinsnap For Bucket'
                            description={"Are you sure you want to push infinsnap for " + bucket.bucketname + " bucket ?"}
                            icon={<QuestionCircleOutlined style={{ color: '#ffb822' }} />}
                            onConfirm={async() => { await toggleInfinsnap('pause')}}
                          >
                            <button className="btn btn-sm btn-label-warning">Pause InfinSnap</button>
                          </Popconfirm>
                        )}

                        {bucket.infinSnapState === "Inactive" &&(
                          <Popconfirm
                            title= 'Enable InfinSnap For Bucket'
                            description={"Are you sure you want to enable infinsnap for " + bucket.bucketname + " bucket ?"}
                            icon={<QuestionCircleOutlined style={{ color: '#1dc9b' }} />}
                            onConfirm={async() => { await toggleInfinsnap('enable')}}
                          >
                            <button className="btn btn-sm btn-label-brand">Enable InfinSnap</button>
                          </Popconfirm>
                        )}

                        {bucket.infinSnapState === "Paused" &&(
                          <Space>
                            <Popconfirm
                              title= 'Restart InfinSnap For Bucket'
                              description={"Are you sure you want to restart infinsnap for " + bucket.bucketname + " bucket ?"}
                              icon={<QuestionCircleOutlined style={{ color: '#1dc9b' }} />}
                              onConfirm={async() => { await toggleInfinsnap('restart')}}
                            >
                              <button className="btn btn-sm btn-label-brand">Restart InfinSnap</button>
                            </Popconfirm>
                            <Popconfirm
                              title= 'Disable Infinsnap For Bucket'
                              description={"Are you sure you want to disable infinsnap for " + bucket.bucketname + " bucket ?"}
                              icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                              onConfirm={async() => { await toggleInfinsnap('disable')}}
                            >
                              <button className="btn btn-sm btn-label-danger">Disable InfinSnap</button>
                            </Popconfirm>
                          </Space>
                        )}            
                        <button className="btn btn-sm btn-label-warning" onClick={()=> {setEditBucketModal(true)}}>
                          <i className="flaticon2-edit icon-1x font-weight-bold" style={{marginRight: 0}}/>
                        </button>
                        <Popconfirm
                          title= 'Delete Bucket'
                          description={"Are you sure you want to delete " + bucket.bucketname + " bucket ?"}
                          icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                          onConfirm={deleteBucket}
                        >
                          <button className="btn btn-sm btn-label-danger">
                            <i className="flaticon2-trash icon-1x btn-label-danger font-weight-bold" style={{marginRight: 0}} />
                          </button>
                        </Popconfirm>
                      </Space>
                    </div>
                    <div className="inf-widget4__item ">
                      <span className="inf-bucket__desc"> <span className="font-weight-bolder font-size-sm">Description : </span> {bucket.description}</span>
                    </div>
                    <div className="inf-widget4__item ">
                      <div className="d-flex align-items-center flex-wrap">               
                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                            <span className="mr-4">
                                <i className="flaticon2-world icon-2x text-muted font-weight-bold"></i>
                            </span>
                            <div className="d-flex flex-column text-dark-75">
                                <span className="font-weight-bolder font-size-sm">cloud</span>
                                <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.cloud} </span>
                            </div>
                        </div>
                    
                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                            <span className="mr-4">
                                <i className="flaticon2-box icon-2x text-muted font-weight-bold"></i>
                            </span>
                            <div className="d-flex flex-column text-dark-75">
                                <span className="font-weight-bolder font-size-sm">Endpoint URL</span>
                                <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.endpoint ? bucket.endpoint : '-'}</span>
                            </div>
                        </div>
                    
                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                            <span className="mr-4">
                                <i className="flaticon2-map icon-2x text-muted font-weight-bold"></i>
                            </span>
                            <div className="d-flex flex-column text-dark-75">
                                <span className="font-weight-bolder font-size-sm">Region</span>
                                <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.region ? bucket.region : '-'} </span>
                            </div>
                        </div>
                        
                      </div>
                    </div>
                    <div className="inf-widget4__item ">
                      <div className="d-flex align-items-center flex-wrap">               
                        {
                          (bucket.accessKeyId || bucket.secretAccessKey) && (
                          <>
                            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                              <span className="mr-4">
                                  <i className="flaticon-security icon-2x text-muted font-weight-bold"></i>
                              </span>
                              <div className="d-flex flex-column text-dark-75">
                                  <span className="font-weight-bolder font-size-sm">AccessKey</span>
                                  <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.accessKeyId ? bucket.accessKeyId : '-'}</span>
                              </div>
                            </div>
                        
                            <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                              <span className="mr-4">
                                  <i className="flaticon2-safe icon-2x text-muted font-weight-bold"></i>
                              </span>
                              <div className="d-flex flex-column text-dark-75">
                                  <span className="font-weight-bolder font-size-sm">SecretAccessKey</span>
                                  <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.secretAccessKey ? bucket.secretAccessKey : '-'} </span>
                              </div>
                            </div>
                          </>
                          )
                        }
                        
                        {
                          (bucket.accessRole || bucket.accessRoleExt) && (
                            <>
                              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                  <span className="mr-4">
                                      <i className="flaticon2-list icon-2x text-muted font-weight-bold"></i>
                                  </span>
                                  <div className="d-flex flex-column text-dark-75">
                                      <span className="font-weight-bolder font-size-sm">IAM Role</span>
                                      <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.accessRole ? bucket.accessRole : '-'}</span>
                                  </div>
                              </div>
                          
                              <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                                  <span className="mr-4">
                                      <i className="flaticon2-safe icon-2x text-muted font-weight-bold"></i>
                                  </span>
                                  <div className="d-flex flex-column text-dark-75">
                                      <span className="font-weight-bolder font-size-sm">IAM Role External ID</span>
                                      <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.accessRoleExt ? bucket.accessRoleExt : '-'} 
                                      </span>
                                  </div>
                              </div>
                            </>
                          )
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="inf-portlet inf-portlet--height-fluid">
                <div className="inf-portlet__body">
                  <div className="inf-widget4">
                    <div className="inf-widget4__item ">
                      <div className="inf-widget4__info ">
                        <span className="inf-widget4__username">Authorization</span>
                      </div>
                      <button className="btn btn-sm btn-label-dark" onClick={addBucketAuth} >Add Permission </button>
                    </div>
                    <div className="inf-widget4__item" style={{display:'block'}}>
                      <Table dataSource={authorization} columns={columns} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {(userRole === "DataScientist") && (
          <div className="inf-portlet">
            <div className="inf-portlet__body">
              <div className="inf-widget4">
                <div className="inf-widget4__item ">
                  <div className="inf-widget4__info ">
                    <span className="inf-widget4__username">{bucket.bucketname}</span>
                    <p className="inf-widget4__text "> <span className="inf-widget4__username">cloud:</span>  {bucket.cloud} </p>
                  </div>
                </div>
                <div className="inf-widget4__item ">
                  <span className="inf-bucket__desc"> <span className="font-weight-bolder font-size-sm">Description : </span> {bucket.description}</span>
                </div>

                <div className="d-flex align-items-center flex-wrap">
                  <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                      <i className="flaticon-web icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-sm">Objects</span>
                      <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.objects}</span>
                    </div>
                  </div>
              
                  <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                      <i className="flaticon-interface-1 icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-sm">Size</span>
                      <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{bucket.size} bytes</span>
                    </div>
                  </div>
              
                  <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                      <i className="flaticon-pie-chart icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-sm">Extract Metadata</span>
                      <span className={`font-weight-bolder font-size-h5 btn btn-xsm 
                        ${clsx({"btn-label-success": (bucket.extractMetadata==="true")})}
                        ${clsx({"btn-label-danger": (bucket.extractMetadata==="false")})}`}>
                          {bucket.extractMetadata==="false" ? "Disabled ":" Enabled "} 
                      </span>
                    </div>
                  </div>
              
                  <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                    <span className="mr-4">
                      <i className="flaticon-infinity icon-2x text-muted font-weight-bold"></i>
                    </span>
                    <div className="d-flex flex-column text-dark-75">
                      <span className="font-weight-bolder font-size-sm">InfinSnap</span>
                      <span className={`font-weight-bolder font-size-h5 btn btn-xsm
                        ${clsx({"btn-label-success": (bucket.infinSnapState==="Active")})}
                        ${clsx({"btn-label-danger": (bucket.infinSnapState==="Inactive")})}
                        ${clsx({"btn-label-warning": (bucket.infinSnapState==="Paused")})}`}>
                          {bucket.infinSnapState}
                      </span> 
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        <BrowseBucket bucket={bucket}> </BrowseBucket>
        <EditBucketModal isOpen={openEditBucketModal} onClose={closeEditBucketModal} bucket={bucket}> </EditBucketModal>
        <BucketAuthorizationModal isOpen={openBucketAuthModal} onClose={closeBucketAuthModal} bucket={bucket} title={modelTitle} okText={okText} auth={auth}/>
      </>
    );
  } else {
    return(<div> Bucket not available </div>)
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = state.user;
  const { params } = ownProps.match;
  const { bucketname } = params;
  const userRole = getUserRole(user);
  const bucket = getBucketInfo(user, bucketname);
  const authorization = getBucketAuthorization(bucket);
  return {user, userRole, bucket, authorization}
}

const mapDispatchToProps = {
  browseBucketByTimeApi,
  lookupOneFileByTimeApi,
  getFileContentApi,
  addModDelBucketApi,
  customerInfoApi,
  removeBucketAuthApi,
  toggleInfinsnapApi
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageBucket);