import React from "react";
import clsx from "clsx";

class MarketPlacePage extends React.Component {

  state = {
    loading: true,
    infinLogLoading: false,
  }

  render () {
    const serviceConfig = window.ServiceConfig;
    const token = Math.random().toString(36).substring(2, 10);
    const {loading, infinLogLoading} = this.state;
    return(
      <>
        <div className="inf-grid inf-grid--ver inf-grid--root">
          <div id="inf_login" className="inf-grid inf-grid--hor inf-grid--root inf-login inf-login--v1">
            <div className="inf-grid__item inf-grid__item--fluid inf-grid inf-grid--desktop inf-grid--ver-desktop inf-grid--hor-tablet-and-mobile">
              <div className="inf-grid__item inf-grid__item--fluid inf-grid__item--order-tablet-and-mobile-1" style={{marginTop: '100px'}}>
                <div className="row">
                  <div className="col-xl-6">
                    <div className="inf-portlet inf-portlet--height-fluid">
                      <div className="inf-portlet__head">
                        <div className="inf-portlet__head-label">
                          <h3 className="inf-portlet__head-title">InfinStor Simulate AWS Marketplace Subscribe</h3>
                        </div>
                      </div>
                      <div className="inf-portlet__body">            
                        <span> This page simulates a marketplace subscribe button press. </span>
                        <form action={"https://" + serviceConfig.ApiServer + "/newsubscriber"} method="POST" >
                          <input type="hidden" name="x-amzn-marketplace-token" id="x-amzn-marketplace-token" value={token}></input>
                          <input className={`btn btn-sm btn-label-brand ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": loading})}`} 
                            type="submit" value="Subscribe InfinStor" onClick={() => { this.setState({ loading: true }) }} >
                          </input>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-6">
                    <div className="inf-portlet inf-portlet--height-fluid">
                      <div className="inf-portlet__head">
                        <div className="inf-portlet__head-label">
                          <h3 className="inf-portlet__head-title">InfinStor Infinlogs Simulate AWS Marketplace Subscribe</h3>
                        </div>
                      </div>
                      <div className="inf-portlet__body">            
                        <span> This page simulates a marketplace subscribe button press </span>
                        <form action={"https://" + serviceConfig.ApiServer + "/newsubscriber"} method="POST" >
                          <input type="hidden" name="x-amzn-marketplace-token" id="x-amzn-marketplace-token" value="dfgfdgsa345435"></input>
                          <input className={`btn btn-sm btn-label-brand ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": loading})}`} 
                            type="submit" value="Subscribe InfinStor Infinlogs" onClick={() => { this.setState({ infinLogLoading: true }) }} >
                          </input>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>  
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}

export default MarketPlacePage;