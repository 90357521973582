import { fulfilled, rejected } from "./utils";

export const GET_CUSTOMER_INFO_API = "GET_CUSTOMER_INFO_API";

const initialUserState = {
  fetching: true,
};
export const reducer = (state = initialUserState, action) => {
  
  switch (action.type) {
    case fulfilled(GET_CUSTOMER_INFO_API): {
      const user = action.payload.userName ? action.payload : state;
      user.fetching = false;
      return user;
    }

    case rejected(GET_CUSTOMER_INFO_API): {
      return {fetching: false};
    }

    case "[Logout] Action": {
      return { fetching: false };
    }

    default:
      return state;
  }
}
