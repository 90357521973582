import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import store, { persistor } from "./app/store/store";
import { setupAxios } from "./app/utils/utils";
import App from "./App";
import "./index.scss"; 
import "./index.css"; 
import "socicon/css/socicon.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/plugins/flaticon/flaticon.css";
import "./assets/plugins/flaticon2/flaticon.css";

const { PUBLIC_URL } = process.env;
setupAxios(axios, store);
ReactDOM.render(
  <App
    store={store}
    persistor={persistor}
    basename={PUBLIC_URL}
    id="infin_search"
  />,
  document.getElementById("root")
);
