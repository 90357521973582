
import React, { Component } from 'react';
import { Input, Form, Select } from 'antd';
import _ from 'lodash';

const { Option } = Select;

/**
 * Component that renders a form for add permission for bucket.
 */
export class BucketAuthorizationForm extends Component {
  componentDidUpdate(prevProps) {
    this.resetFields(prevProps);
  }

  resetFields = (prevProps) => {
    const formRef = this.props.innerRef;
    if (!_.isEqual(prevProps.auth, this.props.auth)) {
      // reset input field to reset displayed initialValue
      formRef.current.resetFields();
    }
  };
  
  render() {
    const {auth, edit} = this.props;
    return (
      <Form ref={this.props.innerRef} layout='vertical' initialValues={auth}>
        <Form.Item name='type' label='Type (user/group)' className="form-lable" rules={[{
          required: true,
          message: 'Please select type!',
        }]}>
          <Select placeholder="Select type (user/group)" disabled={edit}>
            <Option key="user" value="user"> user </Option>
            <Option key="group" value="group"> group </Option>
          </Select>
        </Form.Item>
        
        <Form.Item name='principal' className="form-lable" label="Name (user/group)" 
        rules={[{
          required: true,
          message: 'Please input user/group name!',
        }]}>
          <Input placeholder="name" disabled={edit}/>
        </Form.Item>

        <Form.Item name='role' className="form-lable" label='role' rules={[{
          required: true,
          message: 'Please select role!',
        }]}>
          <Select placeholder="Select role">
            <Option key="reader" value="reader"> Reader </Option>
            <Option key="editor" value="editor"> Editor </Option>
          </Select>
        </Form.Item>
      </Form>
    );
  }
}
