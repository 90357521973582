
import React, { Component } from 'react';
import { Input, Form } from 'antd';

/**
 * Component that renders a form for adding a new user.
 */
export class AddUserForm extends Component {
  render() {
    return (
      <Form ref={this.props.innerRef} layout='vertical'>
        <Form.Item label="User Name" className="form-lable" name='newuser' rules={[{ validator: this.props.nameValidator }]}>
          <Input placeholder="Username" autoFocus />
        </Form.Item>
        <Form.Item name='email' className="form-lable" label="E-mail" 
        rules={[
          () => ({
            validator(_, value) {
              if (!value) {
                return Promise.reject(new Error('E-mail is required!!'));
              } else {
                return Promise.resolve();
              }
            },
          }),
          {
            type: 'email',
            message: 'The input is not valid E-mail!',
          },
        ]}>
          <Input placeholder="e-mail" />
        </Form.Item>
      </Form>
    );
  }
}
