import React, {useEffect, useState}from "react";
import Spinner from "../layout/Spinner";
import clsx from "clsx";
import cogoToast from 'cogo-toast';
import { connect } from "react-redux";
import { Modal, Switch, Space, Tooltip } from 'antd'
import { listSecondaryUsersApi, 
  changeSecondaryUserStatusApi, 
  delteUserAccessKeyApi, 
  createUserAccessKeyApi } from "../store/actions";
import { AddUserModal } from "./AddUserModal";
const { confirm } = Modal;

function UserListPage(props) {
  const [loading, setLoading ] = useState(false);
  const [secondaryUsers, setSecondaryUsers ] = useState([]);
  const [showAddUserModal, setAddUserModalView ] = useState(false);
  const [creatKeyloadings, setCreateKeyLoadings] = useState([]);
  const [deleteKeyloadings, setDeleteKeyLoadings] = useState([]);
  const [toggleloadings, setToggleloadings] = useState([]);
  const [userlist, setUserList] = useState([]);
  const [searchText, setSearchText] = useState('');

  const get_random  =(list) => {
    return list[Math.floor((Math.random()*list.length))];
  }

  const color = ['inf-badge--unified-brand', 'inf-badge--unified-dark', 'inf-badge--unified-primary', 'inf-badge--unified-success', 'inf-badge--unified-info', 'inf-badge--unified-warning', 'inf-badge--unified-danger'];
  const getSecondaryuser = () => {
    setLoading(true);
    props.listSecondaryUsersApi({}).then((result)=> {
      const re = result.value;
      const { users } = re;
      setSecondaryUsers(users);
      searchUser(users, searchText);
      setLoading(false);
    });
  }

  useEffect(() => {
    getSecondaryuser();
  }, []);

  const handleCloseAddUserModal = () => {
    setAddUserModalView(false);
  };

  const searchUser = (users, searchName) => {
    const lowerCasedSearchValue = searchName.toLowerCase();
    lowerCasedSearchValue === '' ? setUserList(users)
      : setUserList(users.filter(({ username }) => username.toLowerCase().includes(lowerCasedSearchValue))); 
  };

  const deletSecondaryuser = (user) =>  new Promise((resolve, reject) => {
    if(user.status === "DISABLED") {
      const postData = "user=" + user.username + "&action=delete";
      props.changeSecondaryUserStatusApi(postData).then((result)=> {
        if(result.value && result.value.startsWith('Successfully performed')){
          getSecondaryuser();
          cogoToast.success('User was successfully deleted.');
          resolve();
        } else {
          cogoToast.error("Something went wrong, Please try again..");
          reject();
        }
      }).catch((e)=> {
        cogoToast.error("Something went wrong, Please try again..");
        reject();
      });
    } else {
      const postData = "user=" + user.username + "&action=disable";
      props.changeSecondaryUserStatusApi(postData).then((result)=> {
        const postData = "user=" + user.username + "&action=delete";
        props.changeSecondaryUserStatusApi(postData).then((result)=> {
          if(result.value && result.value.startsWith('Successfully performed')){
            getSecondaryuser();
            cogoToast.success('User was successfully deleted.');
            resolve();
          } else {
            cogoToast.error("Something went wrong, Please try again..");
            reject();
          }
        }).catch((e)=> {
          cogoToast.error("Something went wrong, Please try again..");
          reject();
        });
      }).catch((e)=> {
        cogoToast.error("Something went wrong, Please try again..");
        reject();
      });
    }
  });

  const deletSecondaryuserConfirm = (user) => {
    confirm({
      title: `Delete user "${user.username}" ?`,
      content: 'This will disable sign-in access and delete this user from the user pool. They will be permanently removed and will no longer be able to sign in.',
      okText: 'Delete',
      okType: 'danger',
      cancelText: 'Cancel',
      width: 700,
      onOk() { return deletSecondaryuser(user)},
      onCancel() {},
    });
  };

  const createAccessKey = (user, index) => {
    setCreateKeyLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    var postData = "user="+ user;
    props.createUserAccessKeyApi(postData).then(request => {
      if(request.value) {
        if(request.value === "Successfully generated key") {
          cogoToast.success(request.value)
        } else {
          cogoToast.error(request.value)
        }
      }
      setCreateKeyLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }).catch((error) => {
      setCreateKeyLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
      cogoToast.error("Something went wrong, Please try again..");
    });
  };

  const deleteAccessKey = (user, index) => {
    setDeleteKeyLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    var postData = "user="+ user;
    props.delteUserAccessKeyApi(postData).then(request => {
      if(request.value) {
        if(request.value === "Successfully deleted key") {
          cogoToast.success(request.value)
        } else {
          cogoToast.error(request.value)
        }
      }
      setDeleteKeyLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }).catch((error) => {
      setDeleteKeyLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
      cogoToast.error("Something went wrong, Please try again..");
    });
  };

  const toggleLoginAccess = async(username, action, index) => {
    setToggleloadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    const doAction = action ? 'enable' : 'disable' ;
    const postData = "user=" + username + "&action=" + doAction;
    await props.changeSecondaryUserStatusApi(postData).then((result)=> {
      if(result.value && result.value.startsWith('Successfully performed')){
        getSecondaryuser();
        cogoToast.success(result.value);
      } else {
        cogoToast.error("Something went wrong, Please try again..");
      }
    }).catch((e)=> {
      cogoToast.error("Something went wrong, Please try again..");
    });
    setToggleloadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = false;
      return newLoadings;
    });
  }

  return (
    <>
      <div className="inf-portlet">
        <div className="inf-widget1" style={{padding: '15px'}}>
          <div className="inf-widget1__item ng-star-inserted">
            <div className="inf-widget1__info">
              {secondaryUsers.length > 0 &&(
                <div className={"input-group"}>
                  <div className="input-group-prepend">
                    <span className="input-group-text inf-search__input_group_text">
                      <i className="flaticon2-search-1" />
                    </span>
                  </div>
                  <input
                    type="text"
                    autoFocus={true}
                    placeholder="Search..."
                    onChange={(event)=> { setSearchText(event.target.value); searchUser(secondaryUsers, event.target.value);}}
                    className="form-control inf-search__input"
                  />
                </div>
              )}
            </div>
            <button className="btn btn-sm btn-label-brand" onClick={()=> {setAddUserModalView(true)}}> Add User </button>
          </div>
        </div>
      </div>
      {(secondaryUsers.length !== 0 && userlist.length === 0) &&(
        <div className="inf-noresult">
          <div>
            <div className="flaticon2-warning inf-noresult-icon"> </div>
            <span className='inf-noresult-text'> oh, No User found.. </span>
          </div>          
        </div>
      )}
      {!loading && secondaryUsers.length === 0 &&(
        <div className="inf-noresult">
          <div className="alert border border-danger" id="user_alert">  
            <div className="flaticon2-warning inf-noresult-icon" style={{fontSize:'2rem'}}> </div>
            <span style={{padding:'5px', fontWeight:'400'}}> 
              No users found.
            </span>
          </div>
        </div>
      )}
      <div className="row">
        {loading && (<Spinner />)}
        {
          userlist.map((user, index) => (
            <React.Fragment key={index}>
              <div className="col-xl-6">
                <div className="inf-portlet">
                  <div className="inf-portlet__body">
                    <div className="inf-widget4">
                      <div className="inf-widget4__item ">
                        <div className="inf-widget4__pic inf-widget4__pic--pic ">
                          <span className={`inf-badge inf-badge--username inf-badge--use-list inf-badge--rounded inf-badge--bold ${get_random(color)}`}>
                            {user.username.substr(0, 2).toUpperCase()}
                          </span>
                        </div>
                        <div className="inf-widget4__info ">
                          <span className="inf-widget4__username">{user.username}</span>
                          <div className="d-flex flex-wrap my-2">
                            <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                              <span className="flaticon2-black-back-closed-envelope-shape mr-1" />
                              {user.email}
                            </span>
                          </div>
                        </div>
                        <Space>
                          <button className="btn btn-sm btn-label-danger" onClick={async () => {deletSecondaryuserConfirm(user)}}>Delete</button>
                        </Space>
                      </div>
                      <div className="d-flex align-items-center flex-wrap">
                        <div className="d-flex align-items-center flex-lg-fill my-1">
                          <span className="mr-4">
                            <i className="flaticon-security icon-2x text-muted font-weight-bold"></i>
                          </span>
                          <div className="d-flex flex-column text-dark-75">
                            <span className="font-weight-bolder font-size-sm">Access Key 
                              <Tooltip title="Manage user access key." color={'#2db7f5'}>
                                <span className="flaticon2-information ml-2 icon-xs"> </span>
                              </Tooltip>
                            </span>
                            <Space size={3}>
                              <button className={`btn btn-xsm btn-label-brand ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": creatKeyloadings[index]})}`} onClick={() => createAccessKey(user.username, index)}>Create</button> 
                              <button className={`btn btn-xsm btn-label-danger ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": deleteKeyloadings[index] })}`} onClick={() => deleteAccessKey(user.username, index)}>Delete</button>
                            </Space>
                          </div>
                        </div>
                    
                        <div className="d-flex align-items-center flex-lg-fill my-1">
                          <span className="mr-4">
                            <i className="flaticon2-user-1 icon-2x text-muted font-weight-bold"></i>
                          </span>
                          <div className="d-flex flex-column text-dark-75">
                            <span className="font-weight-bolder font-size-sm">Login Status</span>
                            <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{user.status}</span>
                          </div>
                        </div>
                    
                        <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                          <span className="mr-4">
                            <i className="flaticon-rotate icon-2x text-muted font-weight-bold"></i>
                          </span>
                          <div className="d-flex flex-column text-dark-75">
                            <span className="font-weight-bolder font-size-sm">Login Access</span>
                            <div className="d-flex ">
                              <Space> 
                                <span className={`font-weight-bolder font-size-h5 btn btn-xsm 
                                  ${clsx({"btn-label-success": (user.status!=="DISABLED")})}
                                  ${clsx({"btn-label-danger": (user.status==="DISABLED")})}`}>
                                    {user.status==="DISABLED" ? "Disabled ":" Enabled "} 
                                </span>
                                <Switch size="small" 
                                  defaultChecked={user.status==="DISABLED" ? false : true}
                                  onChange={(checked)=> {toggleLoginAccess(user.username, checked, index)}} loading={toggleloadings[index]} />
                              </Space>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))
        }
      </div>
      <AddUserModal isOpen={showAddUserModal} onClose={handleCloseAddUserModal} getSecondaryuser={getSecondaryuser}> sssss </AddUserModal>
    </>
  );
}

const mapStateToProps = (state) => {
  const secondaryUser = state.secondaryUser;
  return {secondaryUser}
}

const mapDispatchToProps = {
  listSecondaryUsersApi,
  changeSecondaryUserStatusApi,
  createUserAccessKeyApi, 
  delteUserAccessKeyApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(UserListPage);