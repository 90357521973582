import React from "react";
import { HashRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import { checkAuthorization } from './utils/utils';
import AuthPage from "./auth/AuthPage";
import App from "./App";
class AppRoot extends React.Component {
  render() {
    const { isAuthorized } = this.props;
    if(!isAuthorized) {
      return <AuthPage />
    } else {
      return <Router> <App/> </Router>
    }
  }
}

const mapStateToProps = (state) => {
  const isAuthorized = checkAuthorization(state.auth, state.user);
  return { isAuthorized }
}

export default connect(mapStateToProps, null)(AppRoot);

