import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { getFileContentApi } from "../../store/actions";
import { Skeleton } from "antd";
import { getExtension } from "../../utils/utils";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { coy as style } from 'react-syntax-highlighter/dist/cjs/styles/prism';

function TextPreview(props) {
  const {s3_url, path} = props;
  const [fileLoading, setFileLoading ] = useState(false);
  const [text, setText] = useState('');
  const language = getExtension(path).toLowerCase();
  useEffect(() => {
    if(s3_url) {
      setFileLoading(true);
      props.getFileContentApi(s3_url, false).then((result)=> {
        setText(result.value)
        setFileLoading(false);
      })
    }
  }, [s3_url]);

  return (
    <>
      {fileLoading ? <Skeleton /> : <>
        <SyntaxHighlighter language={language}  style={style}>
          {text}
        </SyntaxHighlighter> 
      </>
      }
    </>
  );
}

const mapDispatchToProps = {
  getFileContentApi,
};

export default connect(null, mapDispatchToProps)(TextPreview);