/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import HeaderDropdownToggle from "./HeaderDropdownToggle";
import * as auth from "../../../store/reducers/auth.reducer";

class UserProfile extends React.Component {
  render() {
    const { user, showHi, showBadge } = this.props;

    return (
      <Dropdown className="inf-header__topbar-item inf-header__topbar-item--user" drop="down" alignRight>
        <Dropdown.Toggle
          as={HeaderDropdownToggle}
          id="dropdown-toggle-user-profile"
        >
          <div className="inf-header__topbar-user">
            {showHi && (
              <span className="inf-header__topbar-welcome inf-hidden-mobile">
                Hi,
              </span>
            )}

            {showHi && (
              <span className="inf-header__topbar-username inf-hidden-mobile">
                {user.userName}
              </span>
            )}

            {/* {showAvatar && <img alt="Pic" src={user.pic} />} */}

            {showBadge && (
              <span className="inf-badge inf-badge--username inf-badge--unified-success inf-badge--lg inf-badge--rounded inf-badge--bold">
                {/* TODO: Should get from currentUser */}
                {user.userName ? user.userName[0]: "" }
              </span>
            )}
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">          
          <div className="inf-notification">
            <Link to="/profile">
              <span className="inf-notification__item">
                <div className="inf-notification__item-icon">
                  <i className="flaticon2-calendar-3 inf-font-success" />
                </div>
                <div className="inf-notification__item-details">
                  <div className="inf-notification__item-title inf-font-bold">
                    My Profile
                  </div>
                  <div className="inf-notification__item-time">
                    Account info and password change
                  </div>
                </div>
              </span>
            </Link>
            <div className="inf-notification__custom">
              <button
                onClick={() =>{ this.props.logout() }}
                className="btn btn-label-brand btn-sm btn-bold"
              >
                Sign Out
              </button>
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = ({ user }) => ({
  user
});

export default connect(mapStateToProps,auth.actions)(UserProfile);
