import { getUserNamesAsSet, getGroupNamesAsSet, getBucketNamesAsSet } from "./utils";

export const UserNameValidator = (rule, name, callback) => {
  const userNamesSet = getUserNamesAsSet();
  if (!name) {
    return Promise.reject(new Error('username is required!'));
  } else if (typeof name === 'string' && name.length && !name.trim()) {
    return Promise.reject(new Error('user name cannot consist only of whitespace!'));
  } else if (userNamesSet.has(name)) {
    return Promise.reject(new Error(`User "${name}" already exists.`));
  } else {
    return Promise.resolve();
  }
};

export const passwordValidator = (rule, password, callback) => {
  if (!password) {
    return Promise.reject(new Error('password is required!'));
  } else if (!/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/i.test(password)) {
    return Promise.reject(new Error('The password must be at least eight characters long and must contain a lowercase letter, an uppercase letter, a number, and a special character.'));
  } else {
    return Promise.resolve();
  }
};

export const bucketNameValidator = (rule, name, callback) => {
  const bucketsNameSet = getBucketNamesAsSet();
  if (!name) {
    return Promise.reject(new Error('bucketname is required!'));
  } else if (typeof name === 'string' && name.length && !name.trim()) {
    return Promise.reject(new Error('bucketname cannot consist only of whitespace!'));
  } else if (bucketsNameSet.has(name)) {
    return Promise.reject(new Error(`Bucketname "${name}" already exists.`));
  } else {
    return Promise.resolve();
  }
};

export const groupNameValidator = (rule, name, callback) => {
  const groupNamesSet = getGroupNamesAsSet();
  if (!name) {
    return Promise.reject(new Error('group is required!'));
  } else if (typeof name === 'string' && name.length && !name.trim()) {
    return Promise.reject(new Error('group name cannot consist only of whitespace!'));
  } else if (groupNamesSet.has(name)) {
    return Promise.reject(new Error(`Group "${name}" already exists.`));
  } else {
    return Promise.resolve();
  }
};
