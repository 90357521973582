import React, {useEffect, useState}from "react";
import { connect } from "react-redux";
import { Tooltip } from 'antd'
import { listSubscribersApi, createCustomTokenApi} from "../store/actions";
import Spinner from "../layout/Spinner";

function SubscriberListPage(props) {
  const [loading, setLoading ] = useState(false);
  const [subscribers, setSubscribers ] = useState([]);

  const get_random  =(list) => {
    return list[Math.floor((Math.random()*list.length))];
  }

  const color = ['inf-badge--unified-brand', 'inf-badge--unified-dark', 'inf-badge--unified-primary', 'inf-badge--unified-success', 'inf-badge--unified-info', 'inf-badge--unified-warning', 'inf-badge--unified-danger'];
  useEffect(() => {
    setLoading(true);
    props.listSubscribersApi({}).then((result)=> {
      const re = result.value;
      setSubscribers(re)
      setLoading(false);
    });
  }, []);

  const downloadToken = (subscriber) => {
    // setdownloadTokenLoading(true);
    var data = {
      expiry_seconds: (10*365*24*60*60),
      custom_token_user_name : subscriber
    };
    props.createCustomTokenApi(data).then((result)=> {
      const value = result.value;
      var tokenData = "Token=" + value.infinstor_token + "\r\n" +
          "Service=" + value.service_name + "\r\n" +
          "TokenType=Custom"; 
          var element = document.createElement('a');
          element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(tokenData));
          element.setAttribute('download', 'token');
          element.style.display = 'none';
          document.body.appendChild(element);
          element.click();
          document.body.removeChild(element);
          // setdownloadTokenLoading(false);
          return null;
    }).catch((e)=> {
      // setdownloadTokenLoading(false);
    });
  }

  return (
    <>
      <div className="row">
        {loading && (<Spinner />)}
        {
          subscribers.map((subscriber, index) => (
            subscriber.userName &&(
              <React.Fragment key={index}>
                <div className="col-xl-6">
                  <div className="inf-portlet">
                    <div className="inf-portlet__body">
                      <div className="inf-widget4">
                        <div className="inf-widget4__item ">
                          <div className="inf-widget4__pic inf-widget4__pic--pic ">
                            <span className={`inf-badge inf-badge--username inf-badge--use-list inf-badge--rounded inf-badge--bold ${get_random(color)}`}>
                              {subscriber.userName.substr(0, 2).toUpperCase()}
                            </span>
                          </div>
                          <div className="inf-widget4__info ">
                            <span className="inf-widget4__username">{subscriber.userName}</span>
                            <div className="d-flex flex-wrap my-2">
                              <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                <span className="flaticon2-black-back-closed-envelope-shape mr-1" />
                                {subscriber.emailId}
                              </span>
                              {/* <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                <span className="flaticon2-box mr-1" />
                                {subscriber.customerArtifactUri}
                              </span>  */}
                            </div>
                          </div>
                          <button className="btn btn-sm btn-label-danger" onClick={async () => {downloadToken(subscriber.userName)}}>Create Token</button>

                        </div>
                        <div className="d-flex align-items-center flex-wrap border-bot">
                          <div className="d-flex align-items-center flex-lg-fill my-1">
                            <span className="mr-4">
                              <i className="flaticon-piggy-bank icon-2x text-muted font-weight-bold"></i>
                            </span>
                            <div className="d-flex flex-column text-dark-75">
                              <span className="font-weight-bolder font-size-sm">customerArtifactUri
                                <Tooltip title="You can specify the custom artifact path." color={'#2db7f5'}>
                                  <span className="flaticon2-information ml-2 icon-xs"> </span>
                                </Tooltip>
                              </span>
                              <span className="text-muted text-hover-primary font-weight-bold mr-lg-8 mr-5 mb-lg-0 mb-2">
                                {subscriber.customerArtifactUri}
                              </span> 
                            </div>
                          </div>
                          {/* <div className="d-flex align-items-center flex-lg-fill my-1">
                            <span className="mr-4">
                              <i className="flaticon-file-2 icon-2x text-muted font-weight-bold"></i>
                            </span>
                            <div className="d-flex flex-column flex-lg-fill">
                              <span className="text-dark-75 font-weight-bolder font-size-sm">InfinSnap</span>
                              <a href="#" className="text-primary font-weight-bolder">e</a>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )
          ))
        }
      </div>
    </>
  );
}

const mapDispatchToProps = {
  listSubscribersApi,
  createCustomTokenApi
};

export default connect(null, mapDispatchToProps)(SubscriberListPage);