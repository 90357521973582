import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputModal } from "../layout/InputModal";
import { bucketNameValidator } from '../utils/Validator';
import { addModDelBucketApi, customerInfoApi } from '../store/actions';
import { getPostData } from '../utils/utils';
import AddBucketForm from './AddBucketForm';
import cogoToast from 'cogo-toast';
export class AddBucketModalmpl extends Component {
  handleAddBucket = async (data) => {
    const {history} = this.props;
    data['op']= 'add';
    await this.props.addModDelBucketApi(getPostData(data)).then(re => {
      if(re.value && re.value ==='Successfully added bucket'){
        this.props.customerInfoApi().then(()=> {
          history.push(`/manage/${data.bucketname}`)
        });
      } else {
        cogoToast.error("Something went wrong, Please try again..");
      }
    }).catch(e=> {
      cogoToast.error("Something went wrong, Please try again..");
    });
  };

  render() {
    const { isOpen } = this.props;
    return (
      <InputModal
        title='Add Bucket'
        okText='Add'
        isOpen={isOpen}
        handleSubmit={this.handleAddBucket}
        onClose={this.props.onClose}
      >
        <AddBucketForm nameValidator={bucketNameValidator} bucket={{}} />
      </InputModal>
    );
  }
}

const mapDispatchToProps = {
  addModDelBucketApi,
  customerInfoApi
};

export const AddBucketModal = connect(null, mapDispatchToProps)(AddBucketModalmpl);
