import React from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import * as auth from "../store/reducers/auth.reducer";
import { AuthenticationDetails } from "amazon-cognito-identity-js";

function CodeVerify(props) {
  const { intl } = props;
  const { cognitoUser, CodeDeliveryDetails } = props.userAttributes;

  return (
    <div className="inf-login__body">
      <div className="inf-login__form">
        <div className="inf-login__title">
          <img
              className="inf-login-logo-default"
              alt="logo"
              src={"/img/InfinStorBaseLogo_Black.png"}
            />
        </div>
        <Formik
          initialValues={{
            code: "",
            password: "",
            confirmPassword: ""
          }}
          validate={values => {
            const errors = {};
            if (!values.code) {
              errors.code = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            }

            if (!values.password) {
              errors.password = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (!/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/i.test(values.password)) {
              errors.password = 'The password must be at least eight characters long and must contain a lowercase letter, an uppercase letter, a number, and a special character.';
            }

            if (!values.confirmPassword) {
              errors.confirmPassword = intl.formatMessage({
                id: "AUTH.VALIDATION.REQUIRED_FIELD"
              });
            } else if (values.password !== values.confirmPassword) {
              errors.confirmPassword =
                "Password and Confirm Password didn't match.";
            }
            return errors;
          }}
          onSubmit={(values, { setStatus, setSubmitting }) => {
            cognitoUser.confirmPassword(values.code, values.password , {
              onSuccess: () => {
                let authenticationData = {
                  Username: cognitoUser.username,
                  Password: values.password
                };
                let authenticationDetails = new AuthenticationDetails(
                  authenticationData
                );
                cognitoUser.authenticateUser(authenticationDetails, {
                  onSuccess: (result )=> {
                    let idToken = result.idToken.jwtToken;
                    let refreshToken = result.refreshToken.token;
                    let accessToken = result.accessToken.jwtToken;
                    let date = new Date();
                    date.setTime(date.getTime()+(57*60*1000));
                    const auth = {
                      idToken: idToken,
                      refreshToken: refreshToken,
                      accessToken: accessToken,
                      tokenTime: date.getTime()
                    };                  
                    props.login(auth);
                    props.getUser({ Username:'', customerId:'', productCode:''})
                  },
                  onFailure: (err) => { 
                    setSubmitting(false);
                    setStatus(intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN"}));
                    props.history.push('/auth/login');
                  }
                });
              },
              onFailure: (error) => {
                setSubmitting(false);
                setStatus('Verification Code is invalid');
              }
            });
          }}
        >
          {({
            values,
            status,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting
          }) => (
            <form onSubmit={handleSubmit} noValidate autoComplete="off">
              {status ? (
                <div role="alert" className="alert alert-danger" style={{margin: '12px'}}>
                  <div className="alert-text">{status}</div>
                </div>
              ) : (
                <div role="alert" className="alert alert-info" style={{margin: '12px'}}>
                  <div className="alert-text">
                    We sent a verification code to <strong>{ CodeDeliveryDetails? CodeDeliveryDetails.Destination : 'your' }</strong> email address. Please check.
                  </div>
                </div>
              )}
              <div className="form-group mb-0">
                <TextField
                  type="code"
                  margin="normal"
                  label="Verification Code"
                  className="inf-width-full"
                  name="code"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.code}
                  helperText={touched.code && errors.code}
                  error={Boolean(touched.code && errors.code)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label="Password"
                  className="inf-width-full"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.password}
                  helperText={touched.password && errors.password}
                  error={Boolean(touched.password && errors.password)}
                />
              </div>

              <div className="form-group">
                <TextField
                  type="password"
                  margin="normal"
                  label="Confirm Password"
                  className="inf-width-full"
                  name="confirmPassword"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.confirmPassword}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                />
              </div>

              <div className="inf-login__actions">
                <Link to="/auth">
                  <button type="button" className="btn btn-secondary btn-elevate inf-login__btn-secondary">
                    Back
                  </button>
                </Link>

                <button
                  disabled={isSubmitting}
                  className="btn btn-primary btn-elevate inf-login__btn-primary"
                >
                  Submit
                </button>
              </div>
              <div className ="copyright">
                © Copyright 2023 InfinStor, Inc. All Rights Reserved.
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

const mapStateToProps = (store) => {
  const auth = store.auth;
  const {userAttributes} = auth;
  return {userAttributes}
}

export default injectIntl(
  connect(
    mapStateToProps,
    auth.actions
  )(CodeVerify)
);
