import { combineReducers } from "redux";
import * as auth from "./reducers/auth.reducer";
import * as user from "./reducers/user.reducer";
import * as apis from "./reducers/api.reducer";
import * as i18n from "../../i18n/i18n";
import { fulfilled, rejected } from "./reducers/utils";

const secondaryUserReducer = (state = [], action) => {
  switch (action.type) {
    case fulfilled('LIST_SECONDARYUSER_API'): {
      const result = action.payload;
      if(result) {
        return result.users;
      }
      return [];
    }

    case rejected('LIST_SECONDARYUSER_API'): {
      return state;
    }

    default:
      return state;
  }
};

const groupReducer = (state = [], action) => {
  switch (action.type) {
    case fulfilled('LIST_COGNITO_GROUP_API'): {
      const result = action.payload;
      if(result) {
        return result;
      }
      return [];
    }

    case rejected('LIST_COGNITO_GROUP_API'): {
      return state;
    }

    default:
      return state;
  }
};

const groupInfoReducer = (state = {}, action) => {
  switch (action.type) {
    case fulfilled('GET_GROUP_API'): {
      const result = action.payload;
      if(result) {
        return {
          ...state,
          [result.GroupName]: result,
        };
      }
      return state;
    }

    case fulfilled('UPDATE_GROUP_API'): {
      const result = action.payload;
      if(result) {
        return {
          ...state,
          [result.GroupName]: result,
        };
      }
      return state;
    }

    case fulfilled('ADD_USER_TO_GROUP_API'): {
      const members = action.payload;
      if(members) {
        return {
          ...state,
          [action.meta.group_name]: { ...state[action.meta.group_name], members },
        };
      }
      return state;
    }

    case fulfilled('REMOVE_USER_FROM_GROUP'): {
      const members = action.payload;
      if(members) {
        return {
          ...state,
          [action.meta.group_name]: { ...state[action.meta.group_name], members },
        };
      }
      return state;
    }
    case rejected('GET_GROUP_API'): {
      return state;
    }

    default:
      return state;
  }
};

const subscribersReducer = (state = [], action) => {
  switch (action.type) {
    case fulfilled('LIST_SUBSCRIBERS_API'): {
      const result = action.payload;
      if(result) {
        return result;
      }
      return [];
    }

    case rejected('LIST_SUBSCRIBERS_API'): {
      return state;
    }

    default:
      return state;
  }
};

export const rootReducer = combineReducers({
  auth: auth.reducer,
  user: user.reducer,
  apis: apis.reducer,
  i18n: i18n.reducer,
  secondaryUser: secondaryUserReducer,
  subscribers: subscribersReducer,
  groups: groupReducer,
  groupInfo: groupInfoReducer,
});
