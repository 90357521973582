import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { TextField } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../store/reducers/auth.reducer";

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem"
  });
  const serviceConfig = window.ServiceConfig;
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };

  const handelKeyDown = () => {
    setAlert(false);
  }
  return (
    <>
      <div className="inf-login__body">
        <div className="inf-login__form">
          <div className="inf-login__title">
          <img
              className="inf-login-logo-default"
              alt="logo"
              src={"/img/InfinStorBaseLogo_Black.png"}
            />
          </div>

          <Formik
            initialValues={{
              username: '',
              password: ''
            }}
            validate={values => {
              const errors = {};

              if (!values.username) {
                errors.username = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              } 
              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD"
                });
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              var authenticationDetails = new AuthenticationDetails({
                  Username: values.username,
                  Password: values.password
                }
              );
              var poolData = {
                UserPoolId : serviceConfig.UserPoolId,
                ClientId : serviceConfig.ClientId
              };
              const userPool = new CognitoUserPool(poolData);
              var userData = {
                Username: values.username,
                Pool: userPool
              };
              var cognitoUser = new CognitoUser(userData);
              cognitoUser.authenticateUser(authenticationDetails, {
                onSuccess: (result )=> {
                  let idToken = result.idToken.jwtToken;
                  let refreshToken = result.refreshToken.token;
                  let accessToken = result.accessToken.jwtToken;
                  let date = new Date();
                  date.setTime(date.getTime()+(57*60*1000));
                  const auth = {
                    idToken: idToken,
                    refreshToken: refreshToken,
                    accessToken: accessToken,
                    tokenTime: date.getTime()
                  };                  
                  props.login(auth);
                  // props.getUser({ Username:'', customerId:'', productCode:''}).then((ele) => {
                    
                  // });
                },
                onFailure: (err) => { 
                  disableLoading();
                  setSubmitting(false);
                  setAlert(true);
                  setStatus(intl.formatMessage({id: "AUTH.VALIDATION.INVALID_LOGIN"}));
                },
                newPasswordRequired: (userAttributes) => {
                  props.setUserAttributes({cognitoUser, userAttributes});
                  props.history.push('/auth/registration');
                },
              });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting
            }) => (
              <form
                noValidate={true}
                autoComplete="off"
                className="inf-form"
                style={{margin: "1rem"}}
                onSubmit={handleSubmit}
              >
                {(status && alert) ? (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                ) : null }

                <div className="form-group">
                  <TextField
                    label="User name"
                    margin="normal"
                    className="inf-width-full"
                    name="username"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handelKeyDown}
                    value={values.username}
                    helperText={touched.username && errors.username}
                    error={Boolean(touched.username && errors.username)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="inf-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    onKeyDown={handelKeyDown}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="inf-login__actions">
                  <Link
                    to="/auth/forgot-password"
                    className="inf-link inf-login__link-forgot"
                  >
                    <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                  </Link>
                  <button id="inf_login_signin_submit" type="submit" disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate inf-login__btn-primary ${clsx(
                      {
                        "inf-spinner inf-spinner--right inf-spinner--md inf-spinner--light": loading
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Sign In
                  </button>
                </div>
                <div className ="copyright">
                  © Copyright 2023 InfinStor, Inc. All Rights Reserved.
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(Login)
);
