import React, { useState } from "react";
import { Modal, Input } from 'antd'
import { connect } from "react-redux";
import { createCustomTokenApi } from "../../store/actions";
import CWSearchCFTPage from "./CWSearchCFTPage";

const { confirm } = Modal;

function ConfigureAWS(props) {
  const [bucketName, setBucketName] = useState();
  const [bucketNameError, setbucketNameError] = useState(false);
  const serviceConfig = window.ServiceConfig;

  const handleBucketNameOnChange = (event) => {
    setBucketName(event.target.value);
    const specialCharformat = /[ `!@#$%^&*()_+\=\[\]{};':"\\|,<>\/?~]/;
    const uppercaseFormat =/[A-Z]/;
    if(!uppercaseFormat.test(event.target.value) && !specialCharformat.test(event.target.value)){
      setbucketNameError(false);
    } else {
      setbucketNameError(true);
    }
  }

  const openLink = (url) => {
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const startInfinstorCFT = () => {
    const { user } = props;
    confirm({
      title: `Prepare Your AWS Account For InfinStor`,
      content: 'A DynamoDB Table for MLflow and an S3 bucket for MLflow Artifacts will be created in your AWS Account. Do you want to proceed?',
      okText: 'Yes ',
      cancelText: 'Cancel',
      width: 600,
      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            var data = {expiry_seconds: (10*365*24*60*60)};
            props.createCustomTokenApi(data).then((result)=> {
              const value = result.value;
              var customToken = value.infinstor_token;
              const apiServer = serviceConfig.ApiServer;
              const apiDns = apiServer.replace('.'+ serviceConfig.Service, '')
              const url = "https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review/?stackName=ForInfinStorMLflow&templateURL=https://s3.amazonaws.com/infinstorcft/" + serviceConfig.cftVersion+ "/infinstor-setup-customer.yaml&param_SecurityToken=" 
                + customToken + "&param_InfinStorUsername=" 
                + user.userName + "&param_apiDnsName=" 
                + apiDns + "&param_serviceAccountId=" 
                + user.awsAccountId + "&param_InfinstorServiceName=" 
                + serviceConfig.Service;
              openLink(url);
              resolve();
            }).catch((e)=> {
              reject();
            });
          });
        } catch {
          // TODO handle Error 
          return;
        }
      },
      onCancel() {},
    });
  }

  const startBucketCreateCFT = () => {
    if(bucketName && bucketName.length > 2) {
      confirm({
        title: 'Allow InfinStor to create a bucket and add permissions to access it',
        content: 'S3 bucket, DynamoDB table will be created and permissions for accessing them will be added to RoleForInfinStorService in your AWS Account. Do you want to proceed?',
        okText: 'Yes ',
        cancelText: 'Cancel',
        width: 600,
        onOk() {
          if((bucketName.length > 2) && !bucketNameError) {
            const url = "https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review/?stackName=BucketForInfinStor-" + bucketName + "&templateURL=https://s3.amazonaws.com/new.docs.infinstor.com/cft/0.1/infinstor-create-bucket.json&param_BucketName=" + bucketName
            openLink(url);
          } else {
            setbucketNameError(true);
          }
        },
        onCancel() {},
      });
    } else {
      setbucketNameError(true);
    }
  }

  return(
    <>
      <div className="inf-portlet__body">
        <div className="row row-no-padding row-col-separator-xl">
          <div className="col-xl-6">
            <div className="inf-widget1" style={{paddingLeft: '1px'}}>
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  <h3 className="inf-widget1__title">Prepare Your AWS Account For InfinStor</h3>
                  <span className="inf-widget1__desc">Run the following Cloud Formation Template to create the MLflow DynamoDB Table, the MLflow Artifacts S3 bucket and an IAM Role for InfinStor service.</span>
                </div>
              </div>
              <div className="inf-widget1__item ng-star-inserted">
                <button className="btn btn-sm btn-label-brand" onClick={startInfinstorCFT}> Create CloudFormation Stack For Infinstor</button>
              </div>
            </div>
          </div>
          <div className="col-xl-6">
            <div className="inf-widget1">
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  <h3 className="inf-widget1__title">Allow InfinStor to create a bucket and add permissions to access it</h3>
                  <span className="inf-widget1__desc">Run the following Cloud Formation Template to create the bucket and add bucket permissions to the role named RoleForInfinStorService.</span>
                </div>
              </div>
              <div className="inf-widget1__item ng-star-inserted">
                <div className="inf-widget1__info">
                  <Input placeholder="Enter Bucket name" onChange={handleBucketNameOnChange}/>
                </div>
                <button className="btn btn-sm btn-label-brand" onClick={startBucketCreateCFT} disabled={bucketNameError}> Create Bucket</button>
              </div>
              <span style={{fontSize: '12px'}}>(e.g)or_t : my-data-bucket</span> <br/>
              {bucketNameError&&(<span className="errext"> {bucketName ? <> Bucket name is invalid.
                <a href="https://docs.aws.amazon.com/AmazonS3/latest/userguide/bucketnamingrules.html?icmpid=docs_amazons3_console" 
                  target="_blank" rel="noopener noreferrer" style={{color: "blue", marginLeft: "10px"}} >
                  See rules for bucket naming.</a> </> : "Please enter a bucketname" }
                </span>
              )}
            </div>
          </div>
        </div>
      </div>
      <CWSearchCFTPage />
    </>
  )   
}

const mapStateToProps = (state) => {
   const user = state.user;
   return { user }
 }

const mapDispatchToProps = {
  createCustomTokenApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureAWS);

