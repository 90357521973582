export const isPendingApi = (action) => {
  return action.type.endsWith('_PENDING');
};

export const pending = (apiActionType) => {
  return `${apiActionType}_PENDING`;
};

export const isFulfilledApi = (action) => {
  return action.type.endsWith('_FULFILLED');
};

export const fulfilled = (apiActionType) => {
  return `${apiActionType}_FULFILLED`;
};

export const isRejectedApi = (action) => {
  return action.type.endsWith('_REJECTED');
};

export const rejected = (apiActionType) => {
  return `${apiActionType}_REJECTED`;
};

export const getUUID = () => {
  const randomPart = Math.random().toString(36).substring(2, 10);
  return new Date().getTime() + randomPart;
};


export async function getFileBlob(path) {
  const getFileRequest = new Request(path, {
    method: 'GET',
    redirect: 'follow',
  });
  const response = await fetch(getFileRequest);
  if (!response.ok) {
    const errorMessage = (await response.text()) || response.statusText;
    throw new Error(errorMessage);
  }
  return response.blob();
}

export function getFileContent(path, isBinary) {
  return new Promise(async (resolve, reject) => {
    try {
      const blob = await getFileBlob(path);
      const fileReader = new FileReader();
      fileReader.onload = (event) => {
        // Resolve promise with artifact contents
        // @ts-expect-error TS(2531): Object is possibly 'null'.
        resolve(event.target.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
      if (isBinary) {
        fileReader.readAsArrayBuffer(blob);
      } else {
        fileReader.readAsText(blob);
      }
    } catch (error) {
      console.error(error);
      reject(error);
    }
  });
}