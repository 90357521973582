
import React, { Component } from 'react';
import { Input, Form } from 'antd';

/**
 * Component that renders a form for create new group.
 */

const { TextArea } = Input;
export class CreateGroupForm extends Component {
  render() {
    const { group } = this.props;
    return (
      <Form ref={this.props.innerRef} layout='vertical' initialValues={group}>
        <Form.Item label="Group Name" className="form-lable" name='group_name' rules={[{ validator: this.props.nameValidator }]}>
          <Input placeholder="Enter groupname" autoFocus disabled={group.group_name }/>
        </Form.Item>
        <Form.Item label="Description" className="form-lable" name='description'>
            <TextArea rows={3} placeholder="description" />
          </Form.Item>
      </Form>
    );
  }
}
