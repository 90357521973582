import React, { useMemo } from "react";
import { dedent } from "dentist";
// https://github.com/conorhastings/react-syntax-highlighter#prism
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
// See https://github.com/PrismJS/prism-themes
import { coy as highlightStyle } from "react-syntax-highlighter/dist/esm/styles/prism";


export default function CodeBlock({ code, language }) {
  const children = useMemo(() => dedent(code), [code]);

  return (
    <div className="inf-portlet__code" style={{overflow: 'auto'}}>
      {/* TODO Add copy icon */}
      <SyntaxHighlighter language={language} style={highlightStyle}>
        {children}
      </SyntaxHighlighter>
    </div>
  );
}