import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InputModal } from "../layout/InputModal";
import { AddUserForm } from './AddUserForm';
import { UserNameValidator } from '../utils/Validator';
import { addSecondaryUserApi } from '../store/actions';
import cogoToast from 'cogo-toast';

export class AddUserModalmpl extends Component {
  handleAddUser = async (values) => {
    var postData = "newuser=" + values.newuser + "&email=" + values.email;
    const result = await this.props.addSecondaryUserApi(postData);
    if(result.value && result.value ==='Successfully created user'){
      this.props.getSecondaryuser();
      cogoToast.success(result.value);
    } else {
      cogoToast.error("Something went wrong, Please try again..");
    }
  };

  render() {
    const { isOpen } = this.props;
    return (
      <InputModal
        title='Add User'
        okText='Add'
        isOpen={isOpen}
        handleSubmit={this.handleAddUser}
        onClose={this.props.onClose}
      >
        <AddUserForm nameValidator={UserNameValidator} />
      </InputModal>
    );
  }
}

const mapDispatchToProps = {
  addSecondaryUserApi,
};

export const AddUserModal = connect(null, mapDispatchToProps)(AddUserModalmpl);
