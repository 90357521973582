import React, {useState} from "react";
import { Formik } from "formik";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import { TextField } from "@material-ui/core";
import { AuthenticationDetails, CognitoUserPool, CognitoUser, CognitoUserAttribute } from "amazon-cognito-identity-js";
import clsx from "clsx";
import * as auth from "../store/reducers/auth.reducer";
import "../../assets/sass/pages/login/login-1.scss";
function SignUpPage(props) {
  const { intl } = props;
  const [loading, setLoadiing] = useState(false);
  const [alert, setAlert] = useState(false);
  const serviceConfig = window.ServiceConfig;

  const handelKeyDown = () => {
    setAlert(false);
  }

  return (
    <>
      <div className="inf-grid inf-grid--ver inf-grid--root">
        <div id="inf_login" className="inf-grid inf-grid--hor inf-grid--root inf-login inf-login--v1">
          <div className="inf-grid__item inf-grid__item--fluid inf-grid inf-grid--desktop inf-grid--ver-desktop inf-grid--hor-tablet-and-mobile">
            <div className="inf-grid__item inf-grid__item--fluid inf-grid__item--order-tablet-and-mobile-1 inf-login__wrapper">
              <div className="inf-login__body">
                <div className="inf-login__form">
                  <div className="inf-login__title">
                    <img
                        className="inf-login-logo-default"
                        alt="logo"
                        src={"/img/InfinStorBaseLogo_Black.png"}
                      />
                  </div>

                  <div className="inf-login__head">
                    <div className="inf-login__desc" style={{paddingTop: '20px', textAlign: 'center'}}> 
                      Welcome to Infinstor, Create your InfinStor Account
                    </div>
                  </div>
                  <Formik
                    initialValues={{
                      username: "",
                      email: "",
                      password: "",
                      confirmPassword: ""
                    }}
                    validate={values => {
                      const errors = {};
                      if (!values.username) {
                        errors.username = intl.formatMessage({
                          id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                      } 

                      if (!values.email) {
                        errors.email = intl.formatMessage({
                          id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                      } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
                        errors.email = 'Invalid email address';
                      }

                      if (!values.password) {
                        errors.password = intl.formatMessage({
                          id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                      } else if (!/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/i.test(values.password)) {
                        errors.password = 'The password must be at least eight characters long and must contain a lowercase letter, an uppercase letter, a number, and a special character.';
                      }

                      if (!values.password) {
                        errors.password = intl.formatMessage({
                          id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                      } else if (!/^(?=\S*?[A-Z])(?=\S*?[a-z])(?=\S*?[0-9])(?=\S*?[^\w\*])\S{8,}$/i.test(values.password)) {
                        errors.password = 'The password must be at least eight characters long and must contain a lowercase letter, an uppercase letter, a number, and a special character.';
                      }

                      if (!values.confirmPassword) {
                        errors.confirmPassword = intl.formatMessage({
                          id: "AUTH.VALIDATION.REQUIRED_FIELD"
                        });
                      } else if (values.password !== values.confirmPassword) {
                        errors.confirmPassword =
                          "Password and Confirm Password didn't match.";
                      }
                      return errors;
                    }}
                    onSubmit={(values, { setStatus, setSubmitting }) => {
                      console.log('supmit', values);
                      setLoadiing(true);
                      var poolData = {
                        UserPoolId : serviceConfig.UserPoolId,
                        ClientId : serviceConfig.ClientId
                      };
                      const userPool = new CognitoUserPool(poolData);
                      var attributeList = [];

                      var dataEmail = {
                        Name : 'email',
                        Value : values.email,
                      };

                      var attributeEmail = new CognitoUserAttribute(dataEmail);
                      attributeList.push(attributeEmail);

                      var dataService = {
                        Name : 'custom:serviceName',
                        Value : serviceConfig.Service
                      };
                      var attributeService = new CognitoUserAttribute(dataService);
                      attributeList.push(attributeService);

                      userPool.signUp(values.username, values.password, attributeList, null, function(err, result) {
                        if (err) {
                          setAlert(true);
                          setSubmitting(false);
                          setStatus(err.message);
                          setLoadiing(false);
                        } else {
                          var cognitoUser = result.user;
                          var authenticationDetails = new AuthenticationDetails({
                            Username: values.username,
                            Password: values.password
                          }
                        );
                          cognitoUser.authenticateUser(authenticationDetails, {
                            onSuccess: (result )=> {
                              let idToken = result.idToken.jwtToken;
                              let refreshToken = result.refreshToken.token;
                              let accessToken = result.accessToken.jwtToken;
                              let date = new Date();
                              date.setTime(date.getTime()+(57*60*1000));
                              const auth = {
                                idToken: idToken,
                                refreshToken: refreshToken,
                                accessToken: accessToken,
                                tokenTime: date.getTime()
                              };                  
                              props.login(auth);
                              window.location.replace('/');
                            },
                            onFailure: (err) => { 
                              // TODO handle error
                            }
                          });
                        }
                      });
                    }}
                  >
                    {({
                      values,
                      status,
                      errors,
                      touched,
                      handleChange,
                      handleBlur,
                      handleSubmit,
                      isSubmitting
                    }) => (
                      <form onSubmit={handleSubmit} noValidate autoComplete="off">
                        {(status && alert) ? (
                          <div role="alert" className="alert alert-danger">
                            <div className="alert-text">{status}</div>
                          </div>
                        ) : null }
                        <div className="form-group mb-0">
                          <TextField
                              type="username"
                              label="UserName"
                              margin="normal"
                              fullWidth={true}
                              name="username"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onKeyDown={handelKeyDown}
                              value={values.username}
                              helperText={touched.username && errors.username}
                              error={Boolean(touched.username && errors.username)}
                          />
                        </div>
                        <div className="form-group mb-0">
                          <TextField
                              type="email"
                              label="Email"
                              margin="normal"
                              fullWidth={true}
                              name="email"
                              onBlur={handleBlur}
                              onChange={handleChange}
                              onKeyDown={handelKeyDown}
                              value={values.email}
                              helperText={touched.email && errors.email}
                              error={Boolean(touched.email && errors.email)}
                          />
                        </div>

                        <div className="form-group mb-0">
                          <TextField
                            type="password"
                            margin="normal"
                            label="Password"
                            className="inf-width-full"
                            name="password"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={handelKeyDown}
                            value={values.password}
                            helperText={touched.password && errors.password}
                            error={Boolean(touched.password && errors.password)}
                          />
                        </div>

                        <div className="form-group">
                          <TextField
                            type="password"
                            margin="normal"
                            label="Confirm Password"
                            className="inf-width-full"
                            name="confirmPassword"
                            onBlur={handleBlur}
                            onChange={handleChange}
                            onKeyDown={handelKeyDown}
                            value={values.confirmPassword}
                            helperText={touched.confirmPassword && errors.confirmPassword}
                            error={Boolean(
                              touched.confirmPassword && errors.confirmPassword
                            )}
                          />
                        </div>
                        <div>
                          <button id="inf_login_signin_submit" type="submit" disabled={isSubmitting}
                            style={{width: '100%'}}
                            className={`btn btn-primary btn-elevate inf-login__btn-primary 
                            ${clsx({ "inf-spinner inf-spinner--right inf-spinner--sm inf-spinner--light": loading })}`}
                          > Create User </button>
                        </div>
                        <div className ="copyright">
                          © Copyright 2023 InfinStor, Inc. All Rights Reserved.
                        </div>
                      </form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
} 

export default injectIntl(connect(null, auth.actions)(SignUpPage));