import React, { Component } from 'react';
import { connect } from "react-redux";
import { getUserRole, getUUID, getGroupInfo, getGroupMembers } from "../../utils/utils";
import { Input, Form, message, Table, Popconfirm } from 'antd';
import clsx from "clsx";
import { listSecondaryUsersApi, 
  listCognitoGroupApi, 
  addUserToGroupApi, 
  deleteGroupApi,
  getGroupApi,
  removeUserFromGroupApi } from "../../store/actions";
import RequestStateWrapper from "../../layout/RequestStateWrapper";
import { QuestionCircleOutlined } from '@ant-design/icons';
import { EditGroupModal } from './EditGroupModal';

class GroupInfoPage extends Component {
  customerInfoApiId = getUUID();
  listSecondaryUsersApiId= getUUID();

  state = {
    groupMembers: [],
    availabeUsers: [],
    addUserLoaders: [],
    userSearchText: '',
    memberSearchTex: '',
    showModal: false,
  }

  componentDidMount() {
    const { groupname } = this.props;
    this.props.listSecondaryUsersApi(this.listSecondaryUsersApiId);
    this.props.getGroupApi({"group_name": groupname},this.customerInfoApiId);
  }

  static getDerivedStateFromProps(props, state) {
    var tempGroupMembers = [];
    var tempAvailabeUsers = [];
    const userSearchValue = state.userSearchText.toLowerCase();
    const memberSearchValue = state.memberSearchTex.toLowerCase();
      if(userSearchValue === '') {
        tempAvailabeUsers = props.availabe_users_list;
      } else {
        tempAvailabeUsers = props.availabe_users_list.filter(({ username }) => username.toLowerCase().includes(userSearchValue))
      }
      
      if(memberSearchValue === '') {
        tempGroupMembers = props.group_member_list;
      } else {
        tempGroupMembers = props.group_member_list.filter(({ username }) => username.toLowerCase().includes(memberSearchValue))
      }
    return {...state,
      groupMembers: tempGroupMembers,
      availabeUsers: tempAvailabeUsers,
    }
  }

  render() {
    const { groupname, availabe_users_list, group_member_list, groupInfo } = this.props;
    const { addUserLoaders, showModal, availabeUsers, groupMembers } = this.state;

    const addUserTOGroup = (user) => {
      const newLoadings = addUserLoaders;
      const { username } = user;
      newLoadings[user.key] = true;
      this.setState({addUserLoaders : newLoadings});
      if(username) {
        this.props.addUserToGroupApi({ group_name: groupname, user_name: username}).then((re) => {
          console.log('re', re);
          this.setState({addUserLoaders : []});
        }).catch(error => message.error("Something went wrong, Please try again.."));
      } else {
        message.error("User detail is invalid. couldn't add this user to group.");
        this.setState({addUserLoaders : []});
      }
    }
    
    const removeUserFromGroup = (user) =>  new Promise((resolve, reject) => {
      console.log('removeUserFromGroup', user); 
      const { username } = user;
      if(username) {
        this.props.removeUserFromGroupApi({ group_name: groupname, user_name: username}).then((re) => {
          console.log('re', re);
          resolve();
        }).catch(error => { message.error("Something went wrong, Please try again.."); reject(error); });
      } else {
        message.error("User detail is invalid. couldn't add this user to group.");
        reject();
      }
    });

    const deleteGroup = () =>  new Promise((resolve, reject) => {
      this.props.deleteGroupApi({"group_name": groupname}).then((re) => {
        resolve();
        this.props.history.push('/groups');
      }).catch(error => { message.error("Something went wrong, Please try again.."); reject(error); });
    });

    const userListColumns = [   
      {
        title: 'User name',
        dataIndex: 'username',
        key: 'username'
      },{
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },{
        title: 'Login status',
        dataIndex: 'status',
        key: 'status'
      },{
        title: '',
        dataIndex: 'removeUser',
        key: 'removeUser',
        render: (_, record) => (
          <button className={`btn btn-sm btn-label-brand ${clsx({"inf-spinner inf-spinner--right inf-spinner-small inf-spinner--light": addUserLoaders[record.key]})}`} onClick={() => { addUserTOGroup(record)}} >Add</button>
        )
      }
    ]
    
    const groupMembersColumns = [   
      {
        title: 'User name',
        dataIndex: 'username',
        key: 'username'
      },{
        title: 'Email',
        dataIndex: 'email',
        key: 'email'
      },{
        title: 'Login status',
        dataIndex: 'status',
        key: 'status'
      },{
        title: '',
        dataIndex: 'removeUser',
        key: 'removeUser',
        render: (_, record) => (
          <Popconfirm
            title= 'Remove the user from group'
            description={"Are you sure you want to remove the user from this group ?"}
            icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
            onConfirm={()=>removeUserFromGroup(record)}
            okText="Yes"
            cancelText="No"
          >
            <button className="btn btn-sm btn-label-danger"> Remove </button>
          </Popconfirm>
        )
      }
    ]

    const searchGroupMember = (searchValue) => {
      const lowerCasedSearchValue = searchValue.toLowerCase();
      if(lowerCasedSearchValue === '') {
        this.setState({groupMembers: group_member_list})
      } else {
        this.setState({groupMembers: group_member_list.filter(({ username }) => username.toLowerCase().includes(lowerCasedSearchValue))})
      }
    };

    const searchAvailableUser = (searchValue) => {
      const lowerCasedSearchValue = searchValue.toLowerCase();
      if(lowerCasedSearchValue === '') {
        this.setState({availabeUsers: availabe_users_list})
      } else {
        this.setState({availabeUsers: availabe_users_list.filter(({ username }) => username.toLowerCase().includes(lowerCasedSearchValue))})
      }
    };

    const handleCloseModal = () => {
      this.setState({showModal: false});
    }

    return (
      <>
        <RequestStateWrapper requestIds={[this.customerInfoApiId, this.listSecondaryUsersApiId]}> 
        <div className="inf-portlet inf-portlet--height-fluid">
          <div className="inf-portlet__body">
            <div className="inf-widget4">
              <div className="inf-widget4__item">
                <div className="inf-widget4__info">
                  <span className="inf-widget4__username">Group Info</span>
                </div>
                <Popconfirm
                  title= 'Delete the group'
                  description={"Are you sure you want to delete this group ?"}
                  icon={<QuestionCircleOutlined style={{ color: 'red' }} />}
                  onConfirm={()=>deleteGroup()}
                  okText="Yes"
                  cancelText="No"
                >
                  <button className="btn btn-sm btn-label-danger"> delete </button>
                </Popconfirm>
              </div>

              <div className="d-flex align-items-center flex-wrap">
                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="mr-4">
                    <i className="flaticon-users-1 icon-2x text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column text-dark-75">
                    <span className="font-weight-bolder font-size-sm">Group name</span>
                    <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{groupInfo.GroupName}</span>
                  </div>
                </div>
            
                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="mr-4">
                    <i className="flaticon-calendar-with-a-clock-time-tools icon-2x text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column text-dark-75">
                    <span className="font-weight-bolder font-size-sm">Created time</span>
                    <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{groupInfo.CreationDate}</span>
                  </div>
                </div>

                <div className="d-flex align-items-center flex-lg-fill mr-5 my-1">
                  <span className="mr-4">
                    <i className="flaticon-calendar-with-a-clock-time-tools icon-2x text-muted font-weight-bold"></i>
                  </span>
                  <div className="d-flex flex-column text-dark-75">
                    <span className="font-weight-bolder font-size-sm">Last updated time</span>
                    <span className="font-weight-bolder font-size-h5"><span className="text-dark-50 font-weight-bold"></span>{groupInfo.LastModifiedDate}</span>
                  </div>
                </div>
              </div>
              <div className="inf-widget4__item"></div>
              <div className="inf-widget4__item">
                <span className="inf-bucket__desc"> <span className="font-weight-bolder font-size-sm">Description : </span> {groupInfo.Description} </span>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xl-6">
          <div className="inf-portlet inf-portlet--height-fluid">
            <div className="inf-portlet__body">
              <div className="inf-widget4">
                <div className="inf-widget4__item ">
                  <div className="inf-widget4__info ">
                    <span className="inf-widget4__username">Group members</span>
                  </div>
                </div>
                <div className="inf-widget4__item" style={{display:'block'}}>            
                  <div className={"input-group"}>
                    <div className="input-group-prepend">
                      <span className="input-group-text inf-search__input_group_text">
                        <i className="flaticon2-search-1" />
                      </span>
                    </div>
                    <input
                      type="text"
                      autoFocus={true}
                      placeholder="Search member..."
                      onChange={(event)=> {this.setState({memberSearchTex: event.target.value})}}
                      className="form-control inf-search__input"
                    />
                  </div>
                  <Table dataSource={groupMembers} columns={groupMembersColumns} pagination locale={{
                    emptyText: <span> No users found </span>,
                  }}/>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="col-xl-6">
          <div className="inf-portlet inf-portlet--height-fluid">
            <div className="inf-portlet__body">
              <div className="inf-widget4">
                <div className="inf-widget4__item ">
                  <div className="inf-widget4__info ">
                    <span className="inf-widget4__username">Select available users to add to the group.</span>
                  </div>
                </div>
                <div className="inf-widget4__item" style={{display:'block'}}>            
                  <div className={"input-group"}>
                    <div className="input-group-prepend">
                      <span className="input-group-text inf-search__input_group_text">
                        <i className="flaticon2-search-1" />
                      </span>
                    </div>
                    <input
                      type="text"
                      placeholder="Search availabe user"
                      onChange={(event)=> {this.setState({userSearchText: event.target.value})}}
                      className="form-control inf-search__input"
                    />
                  </div>
                  <Table dataSource={availabeUsers} columns={userListColumns} pagination locale={{
                    emptyText: <span> No users found </span>,
                  }}/>
                </div>
              </div>
            </div>
          </div>
          </div>
        </div>
        </RequestStateWrapper>
        <EditGroupModal isOpen={showModal} onClose={handleCloseModal} group={{}} /> 
      </>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = state.user;
  const { params } = ownProps.match;
  const { groupname } = params;
  const secondaryUser = state.secondaryUser;
  const groupInfo = getGroupInfo(state.groupInfo, groupname);
  const groupMember = getGroupMembers(groupInfo);
  const group_member_list = secondaryUser.filter((user) => groupMember.find(member => user.username  === member.name ));
  group_member_list.forEach((element, index )=> { element['key'] = index });
  const availabe_users_list = secondaryUser.filter((user) => !groupMember.find(member => user.username  === member.name ));
  availabe_users_list.forEach((element, index )=> { element['key'] = index });
  return {user, groupname, group_member_list, availabe_users_list, groupInfo}
}

const mapDispatchToProps = {
  getGroupApi,
  listCognitoGroupApi,
  listSecondaryUsersApi,
  addUserToGroupApi,
  removeUserFromGroupApi,
  deleteGroupApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupInfoPage);
