import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { HashRouter as Router } from "react-router-dom";
import { connect } from "react-redux";
import * as auth from "../store/reducers/auth.reducer";
import "../../assets/sass/pages/login/login-1.scss";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import CodeVerify from "./CodeVerify";
import SignUpPage from "./SignUpPage";
import MarketPlacePage from "./MarketPlacePage";
import SuperAdminLoginPage from "./SuperAdminLoginPage";

function AuthPage(props) {
  const serviceConfig = window.ServiceConfig;
  const params = new URLSearchParams(window.location.search);

  const loginWithCode = (code) => {
    let urlEncodedData = "",
        urlEncodedDataPairs = [];

    urlEncodedDataPairs.push(
      encodeURIComponent( "grant_type" ) + '=' + encodeURIComponent("authorization_code") );
    urlEncodedDataPairs.push(
      encodeURIComponent( "code" ) + '=' + encodeURIComponent(code) );
    urlEncodedDataPairs.push(
      encodeURIComponent( "client_id" ) + '=' + encodeURIComponent(serviceConfig.ClientId) );
    urlEncodedDataPairs.push(
      encodeURIComponent( "redirect_uri" ) + '=' + encodeURIComponent(
        "https://" + serviceConfig.ServiceServer) );
    urlEncodedData = urlEncodedDataPairs.join( '&' ).replace( /%20/g, '+' );

    let xhr = new XMLHttpRequest();
    let cognitoDomain = serviceConfig.ServiceServer.replace(/\./g, "-");
    let region = serviceConfig.UserPoolId.split('_')[0];
    let url = 'https://' + cognitoDomain + '.auth.' + region + '.amazoncognito.com/oauth2/token';

    xhr.open("POST", url);
    xhr.responseType = 'json';
    xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
    xhr.send(urlEncodedData);
    xhr.onload = () => {
      const responce = xhr.response;
      let date = new Date();
      date.setTime(date.getTime()+(57*60*1000));
      const auth = {
        idToken: responce['id_token'],
        refreshToken: responce['refresh_token'],
        accessToken: responce['access_token'],
        tokenTime: date.getTime()
      };                  
      props.login(auth);
      window.location.replace('https://' + serviceConfig.ServiceServer);
    }
  }

  if(params.has("code")) {
    loginWithCode(params.get("code"));
    return(
      <div id="startup-screen" className="inf-startup-screen">
        <img src="/img/InfinStorBaseLogo_Black.png" alt="Infinstor logo" />
        <svg className="spinner" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2"></circle>
        </svg>
      </div>
    );
  } else if(params.has("showSignUp")) { 
    return <SignUpPage />
  } else if (serviceConfig.hasOwnProperty('IsExternalOauth') && serviceConfig.IsExternalOauth === "true") {
    let cognitoDomain = serviceConfig.ServiceServer.replace(/\./g, "-");
    let region = serviceConfig.UserPoolId.split('_')[0];
    let url = 'https://' + cognitoDomain + '.auth.' + region
      + '.amazoncognito.com/login?response_type=code&scope=openid+email+profile&client_id='
      + serviceConfig.ClientId + '&redirect_uri=https://'+ serviceConfig.ServiceServer;
    window.location.replace(url)
    return(
      <div id="startup-screen" className="inf-startup-screen">
        <img src="/img/InfinStorBaseLogo_Black.png" alt="Infinstor logo" />
        <svg className="spinner" viewBox="0 0 50 50">
          <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2"></circle>
        </svg>
      </div>
    )
  } else {
    return (
      <>
        <div className="inf-grid inf-grid--ver inf-grid--root">
          <div id="inf_login" className="inf-grid inf-grid--hor inf-grid--root inf-login inf-login--v1">
            <div className="inf-grid__item inf-grid__item--fluid inf-grid inf-grid--desktop inf-grid--ver-desktop inf-grid--hor-tablet-and-mobile">
              {/* <div className="inf-grid__item inf-grid__item--order-tablet-and-mobile-2 inf-grid inf-grid--hor inf-login__aside">
                <div style={{ display: "flex",justifyContent: "end"}}>
                  <img className="inf-login-logo-default" alt="logo"
                    src={"/img/loginCover.svg"}
                  />
                </div>
              </div> */}
              <div className="inf-grid__item inf-grid__item--fluid inf-grid__item--order-tablet-and-mobile-1 inf-login__wrapper">
                <Router>
                  <Switch>
                    <Route path="/marketplace" component={MarketPlacePage} />
                    <Route path="/superadmin" component={SuperAdminLoginPage} />
                    <Route path="/auth/login" component={Login} />
                    <Route path="/auth/registration" component={Registration} />
                    <Route path="/auth/forgot-password" component={ForgotPassword} />
                    <Route path="/auth/codeVerify" component={CodeVerify} />
                    <Redirect exact={true} to="/auth/login" />
                    <Redirect to="/auth/login" />
                  </Switch>
                </Router>  
             </div>
            </div>
          </div>
        </div>
      </>
    );
  }  
}

export default connect( null, auth.actions)(AuthPage)