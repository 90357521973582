import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Formik } from "formik";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { AuthenticationDetails, CognitoUserPool, CognitoUser } from "amazon-cognito-identity-js";
import { TextField, TextareaAutosize } from "@material-ui/core";
import clsx from "clsx";
import * as auth from "../store/reducers/auth.reducer";
import { Input } from 'antd';

const { TextArea } = Input;
function SuperAdminLoginPage(props) {
  const [userToken, setUserToken] = useState();
  const [userTokenError, setUserTokenError] = useState('');
  const [loading, setloading] = useState(false);
  
  const saveUserToken = () => {
    if(userToken.startsWith('Custom')) {
      let date = new Date();
      date.setTime(date.getTime()+(57*60*1000));
      const auth = {
        idToken: userToken,
        refreshToken: undefined,
        accessToken: undefined,
        tokenTime: date.getTime()
      }; 
      props.login(auth);                 
      props.history.push('/data');
    } else {
      var tokenRegex = /[a-zA-Z0-9\-_]+?\.[a-zA-Z0-9\-_]+?\.([a-zA-Z0-9\-_]+)$/
      var regexResult = tokenRegex.exec(userToken);
      if (!regexResult) {
        setUserTokenError('Invalid token. Please enter valid token.');
      } else {
        setloading(true);
        const expiry = (JSON.parse(atob(userToken.split('.')[1]))).exp;
        if((Math.floor(new Date().getTime() / 1000)) >= expiry) {
          setUserTokenError('Token expired . Please enter valid token.');
          setloading(false);
          return;
        }
        let date = new Date();
        date.setTime(date.getTime()+(57*60*1000));
        const auth = {
          idToken: userToken,
          refreshToken: undefined,
          accessToken: undefined,
          tokenTime: date.getTime()
        }; 
        props.login(auth);                 
        props.history.push('/data');
      }
    }
  }

  const serviceConfig = window.ServiceConfig;
  return (
    <>
      <div className="inf-login__body">
        <div className="inf-login__form">
          <div className="inf-login__title">
            <img className="inf-login-logo-default" alt="logo" src={"/img/InfinStorBaseLogo_Black.png"} />
          </div>
          <div className="inf-login__head">
            <div className="inf-login__desc" style={{paddingTop: '20px', textAlign: 'center'}}> 
              Enter infinstor user token
            </div>
          </div>
          <div style={{paddingTop: '20px'}}>
            <TextArea onChange={(event)=> {
              setUserTokenError('');
              setUserToken(event.target.value)}}
              placeholder="Enter Refresh Token"
              autoSize={{
                minRows: 5,
                maxRows: 10,
              }}
            />
          </div>
          <span className="error_text" style={{paddingTop: '20px'}}> {userTokenError} </span>

          <div style={{paddingTop: '20px'}}>
            <button id="inf_login_signin_submit" onClick={saveUserToken} style={{width: '100%'}}
              className={`btn btn-primary btn-elevate inf-login__btn-primary 
              ${clsx({ "inf-spinner inf-spinner--right inf-spinner--sm inf-spinner--light": loading })}`}
            > Login </button>
          </div>
          <div className ="copyright">
            © Copyright 2023 InfinStor, Inc. All Rights Reserved.
          </div>
        </div>
      </div>
    </>
  );
}

export default injectIntl(
  connect(
    null,
    auth.actions
  )(SuperAdminLoginPage)
);
