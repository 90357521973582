import React from "react";
import Header from "./header/Header";
import { withRouter } from "react-router-dom";
import HeaderMobile from "./header/HeaderMobile";
import LayoutConfig from "./LayoutConfig";
import AsideLeft from "./aside/AsideLeft";
import HTMLClassService from "./HTMLClassService";
import "./assets/Base.scss";

const htmlClassService = new HTMLClassService();

function Layout(props) {
  htmlClassService.setConfig(LayoutConfig);
  // scroll to top after location changes
  window.scrollTo(0, 0);

  const contentCssClasses = htmlClassService.classes.content.join(" ");
  return (<>
    {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
    {/* <!-- end:: Header Mobile --> */}

     <div className="inf-grid inf-grid--hor inf-grid--root">
      <div className="inf-grid__item inf-grid__item--fluid inf-grid inf-grid--ver inf-page">
        <AsideLeft />
        <div
          className="inf-grid__item inf-grid__item--fluid inf-grid inf-grid--hor inf-wrapper"
          id="inf-wrapper"
        >
          <Header/>
            <div id="inf_content"
              className={`inf-content ${contentCssClasses} inf-grid__item inf-grid__item--fluid inf-grid inf-grid--hor`}
            >
              {props.children}
            </div>
        </div>
      </div>
    </div> 
  </>)
}

export default withRouter(Layout);
