import { getUUID, getFileContent } from "./reducers/utils";
import { Service } from "./services";
import { accessCookie } from "../utils/utils";
import store from "./store";

export const actions = {
  getCustomerInfo: "GET_CUSTOMER_INFO_API",
  createGroup: "CREATE_GROUP_API",
  updateGroup: "UPDATE_GROUP_API",
  getGroup: "GET_GROUP_API",
  deleteGroup: "DELETE_GROUP_API",
  listCognitoGroup: "LIST_COGNITO_GROUP_API",
  listGroupMembers: "LIST_GROUP_MEMBERS_API",
  addUserToGroup: "ADD_USER_TO_GROUP_API",
  removeUserFromGroup: "REMOVE_USER_FROM_GROUP",
  listSecondaryUsers: "LIST_SECONDARYUSER_API",
  browseBucketByTime: "BROWSE_BUCKET_BY_TIME_API",
  addModDelBucket: "ADD_MOD_DELETE_BUCKET_API",
  toggleInfinsnap: "TOGGLE_INFINSNAP",
  setExtractmetadata: "SET_EXTRACTMETA_API",
  addBucketAuth: "ADDBUCKET_AUTH_API",
  removeBucketAuth: "REMOVE_BUCKET_AUTH_API",
  lookupOneFileByTime: "GETFILESTATUS_API",
  addSecondaryUser: "ADD_SECONDARYUSER_API",
  changeSecondaryUserStatus: "CHANGE_SECONDARYUSER_STATUS_API",
  createUserAccessKey: "CRETAE_USER_ACCESSKEY_API",
  deleteUserAccessKey: "DELETE_USER_ACCESSKEY_API",
  createCustomToken: "CREATE_CUSTOM_TOKEN_API",
  revokeAccessToken: "REVOKE_ACCESS_TOKEN_API",
  changeArtifact: "CHANGE_ARTIFACT_URI",
  setUsepresignedurl: "SET_USE_PRESIGNEDURL",
  listSubscribers: "LIST_SUBSCRIBERS_API",
  newSubscriber: "NEW_SUBSCRIBER"
};

const getIndexTime = () => {
  const { configuration } = store.getState();
  let currentDate = new Date();
  let year = currentDate.getFullYear();
  let month = currentDate.getMonth();
  let day = currentDate.getDate();
  let hours = currentDate.getHours();

  let roundedDownEnd = new Date(year, month, day, hours - 1, 0, 0, 0 );
  let roundedDownStart = new Date(year, month, day - 1, hours - 1, 0, 0, 0 );

  const payload = {};
  if(!configuration.startTime && !configuration.endTime ) {
    payload['start_time'] = roundedDownStart.valueOf();
    payload['end_time'] = roundedDownEnd.valueOf();
  } else {
    if(typeof configuration.startTime === "string") {
      payload['start_time'] = new Date(configuration.startTime).valueOf();
      payload['end_time'] = new Date(configuration.endTime).valueOf();
    } else {
      payload['start_time'] = configuration.startTime.valueOf();
      payload['end_time'] = configuration.endTime.valueOf();
    }
    
  }
  return payload;
}

const getCommonPayload = () => {
  const { configuration } = store.getState();
  const payload = getIndexTime();
  if(configuration.filterType === "filter_by_tag") {
    if(configuration.tagKey && configuration.tagValue) {
      const tag = configuration.tagKey + '=' + configuration.tagValue;
      payload['tag'] =  tag;
    }
  } else if(configuration.filterType === "filter_by_log_group") {
    if(configuration.logGroups) {
      payload['loggroups'] = configuration.logGroups;
    }
  }
  return payload;
}

export const customerInfoApi = (id = getUUID()) => {
  const customerId = accessCookie('CustomerID');
  const productCode = accessCookie('ProductCode');
  var postData = "Username=&customerId=" + customerId + "&productCode=" + productCode;
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.getCustomerInfo,
      payload: Service.getCustomerInfo(postData),
      meta: { id },
    });
    return serveResponse;
  };
};

export const createGroupApi = (data, id = getUUID()) => {
  return {
    type: actions.createGroup,
    payload: Service.createGroup(data),
    meta: {id},
  };
};

export const updateGroupApi = (data, id = getUUID()) => {
  return {
    type: actions.updateGroup,
    payload: Service.updateGroup(data),
    meta: {id},
  };
};

export const getGroupApi = (data, id = getUUID()) => {
  return {
    type: actions.getGroup,
    payload: Service.getGroup(data),
    meta: {id},
  };
};

export const deleteGroupApi = (data, id = getUUID()) => {
  return {
    type: actions.deleteGroup,
    payload: Service.deleteGroup(data),
    meta: {id},
  };
};

export const listCognitoGroupApi = (id = getUUID()) => {
  const payload = {"limit": 2};
  return {
    type: actions.listCognitoGroup,
    payload: Service.listCognitoGroup(payload),
    meta: {id},
  };
};

export const listCognitoGroupMembersApi = (data, id = getUUID()) => {
  return {
    type: actions.listGroupMembers,
    payload: Service.listGroupMembers(data),
    meta: {id},
  };
};

export const addUserToGroupApi = (data, id = getUUID()) => {
  return {
    type: actions.addUserToGroup,
    payload: Service.addUserToGroup(data),
    meta: {id, group_name: data.group_name},
  };
};

export const removeUserFromGroupApi = (data, id = getUUID()) => {
  return {
    type: actions.removeUserFromGroup,
    payload: Service.removeUserFromGroup(data),
    meta: {id, group_name: data.group_name},
  };
};

export const browseBucketByTimeApi = (path, data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.browseBucketByTime,
      payload: Service.browseBucketByTime(path, data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const lookupOneFileByTimeApi = (path, data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.lookupOneFileByTime,
      payload: Service.lookupOneFileByTime(path, data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const getFileContentApi = (path, isBinary, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: "GET_FILE",
      payload: getFileContent(path, isBinary),
      meta: { id },
    });
    return serveResponse;
  };
};

export const addModDelBucketApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.addModDelBucket,
      payload: Service.add_mod_delete_bucket(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const toggleInfinsnapApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.toggleInfinsnap,
      payload: Service.toggleInfinsnap(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const setExtractmetadataApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.setExtractmetadata,
      payload: Service.setExtractmetadata(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const addBucketAuthApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.addBucketAuth,
      payload: Service.addBucketAuth(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const removeBucketAuthApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.removeBucketAuth,
      payload: Service.removeBucketAuth(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const listSecondaryUsersApi = (id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.listSecondaryUsers,
      payload: Service.listSecondaryUsers(''),
      meta: { id },
    });
    return serveResponse;
  };
};

export const addSecondaryUserApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.addSecondaryUser,
      payload: Service.addSecondaryUser(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const changeSecondaryUserStatusApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.changeSecondaryUserStatus,
      payload: Service.changeSecondaryUserStatus(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const createUserAccessKeyApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.createUserAccessKey,
      payload: Service.createUserAccessKey(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const delteUserAccessKeyApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.deleteUserAccessKey,
      payload: Service.deleteUserAccessKey(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const createCustomTokenApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.createCustomToken,
      payload: Service.createCustomToken(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const revokeAccessTokenApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.revokeAccessToken,
      payload: Service.revokeAccessToken(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const changeArtifactURIApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.changeArtifact,
      payload: Service.changeArtifactURI(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const setUsepresignedurlApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.setUsepresignedurl,
      payload: Service.setUsepresignedurl(data),
      meta: { id },
    });
    return serveResponse;
  };
};

export const newSubscriberApi = (data, id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.newSubscriber,
      payload: Service.newSubscriber(data),
      meta: { id },
    });
    return serveResponse;
  };
};

// superAdmin Api
export const listSubscribersApi = (id = getUUID()) => {
  return (dispatch) => {
    const serveResponse = dispatch({
      type: actions.listSubscribers,
      payload: Service.listSubscribers(''),
      meta: { id },
    });
    return serveResponse;
  };
};