import React from "react";
import { Modal } from 'antd'
import { connect } from "react-redux";
import { uuidv4 } from "../../utils/utils";
import { createCustomTokenApi } from "../../store/actions";
const { confirm } = Modal;

function CWSearchCFTPage(props) {
  const serviceConfig = window.ServiceConfig;

  const openLink = (url) => {
    var element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  const startCWSearchCFT = () => {
    const { user } = props;
    confirm({
      title: 'Prepare your AWS Account for InfinLogs for CloudWatch',
      content: 'A Role will be created in your AWS account to allow us to read your CloudWatch Logs. Do you want to proceed?',
      okText: 'Yes ',
      cancelText: 'Cancel',
      width: 600,
      async onOk() {
        try {
          return await new Promise((resolve, reject) => {
            var data = {expiry_seconds: (10*365*24*60*60)};
            props.createCustomTokenApi(data).then((result)=> {
              const value = result.value;
              var customToken = value.infinstor_token;
              const uuid = uuidv4();
              const url = "https://us-east-1.console.aws.amazon.com/cloudformation/home?region=us-east-1#/stacks/create/review/?stackName=ForInfinLogs-" + 
                user.userName + "&templateURL=https://s3.amazonaws.com/new.docs.infinstor.com/cft/infinlogs/0.1/cwsearch-setup-customer.yaml&param_SecurityToken=" + 
                customToken + "&param_InfinstorUsername=" + 
                user.userName + "&param_CwSearchInfinsnapBucketName=infinlogs-cache-" + 
                uuid;
              openLink(url);
              resolve();
            }).catch((e)=> {
              reject();
            });
          });
        } catch {
          // TODO handle Error 
          return;
        }
      },
      onCancel() {},
    });
  }

  return(
    <>
      <div className="inf-portlet__body" style={{ borderTop: "10px solid #f2f3f8"}}>
        <div className="inf-widget1" style={{padding: '1px', paddingRight:'25px'}}>
          <div className="inf-widget1__item ng-star-inserted">
            <div className="inf-widget1__info">
              <h3 className="inf-widget1__title">Prepare your AWS Account for InfinLogs for CloudWatch</h3>
              <span className="inf-widget1__desc">Run the following Cloud Formation Template to create required resources such as an IAM Role for InfinStor to access your CloudWatch Logs.</span>
            </div>
            <button className="btn btn-sm btn-label-brand" onClick={startCWSearchCFT}> Create CloudFormation Stack For InfinLogs</button>
          </div>
        </div>
      </div>
    </>
  )   
}

const mapStateToProps = (state) => {
   const user = state.user;
   return { user }
 }

const mapDispatchToProps = {
  createCustomTokenApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(CWSearchCFTPage);

