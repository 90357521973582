import React, {useEffect, useState}from "react";
import Spinner from "../../layout/Spinner";
import clsx from "clsx";
import cogoToast from 'cogo-toast';
import { connect } from "react-redux";
import { Radio, Space, Table, Tag } from 'antd';
import { Link } from "react-router-dom";

import { listCognitoGroupApi } from "../../store/actions";
import { CreateGroupModal } from "./CreateGroupModal";

function GroupListPage(props) {
  const {history} = props;
  const [loading, setLoading ] = useState(false);
  const [groupList, setGroupList ] = useState([]);
  const [filterList, setFilterGroupList ] = useState([]);
  const [showModal, setShowModal ] = useState(false);
  
  const get_random  =(list) => {
    return list[Math.floor((Math.random()*list.length))];
  }

  const handleCloseModal = () => {
    setShowModal(false);
  }
  
  const searchGroup = (list, searchValue) => {
    const lowerCasedSearchValue = searchValue.toLowerCase();
    if(lowerCasedSearchValue === '') {
      setFilterGroupList(list)
    } else {
      setFilterGroupList(list.filter(({ GroupName }) => GroupName.toLowerCase().includes(lowerCasedSearchValue)))
    }
  };

  const color = ['inf-badge--unified-brand', 'inf-badge--unified-dark', 'inf-badge--unified-primary', 'inf-badge--unified-success', 'inf-badge--unified-info', 'inf-badge--unified-warning', 'inf-badge--unified-danger'];
  const getGroupList = () => {
    setLoading(true);
    props.listCognitoGroupApi().then((result)=> {
      const groups = result.value;
      groups.forEach((element, index )=> {
        element['key'] = index
      });
      setGroupList(groups);
      setLoading(false);
      searchGroup(groups,'');
    });
  }

  useEffect(() => {
    getGroupList();
  },[]);

  const columns = [
    {
      title: 'Group name',
      dataIndex: 'GroupName',
      key: 'GroupName',
      render: (text) => <Link to={`/group/${text}`}> <span className="btn-label-brand">{text}</span> </Link>
    },
    {
      title: 'Description',
      dataIndex: 'Description',
      key: 'Description',
    },
    {
      title: 'Created time',
      dataIndex: 'CreationDate',
      key: 'CreationDate',
      // render: (text) => <a>{text.replace('.', ' ')}</a>,
    },
    {
      title: 'Last updated time',
      dataIndex: 'LastModifiedDate',
      key: 'LastModifiedDate',
      // render: (text) => <a>{text.replace('.', ' ')}</a>,
    }
  ];
  const data = [ ];
  
  return (
    <>
      {!loading && (
        <div className="inf-portlet inf-portlet--height-fluid">
          <div className="inf-portlet__body">
            <div className="inf-widget4">
              <div className="inf-widget4__item ">
                <div className="inf-widget4__info ">
                  <span className="inf-widget4__username">Groups</span>
                  <span> Configure groups and add users. Groups can be used to add permissions to the access buckets. </span>

                </div>
                <button className="btn btn-sm btn-label-brand" onClick={() => { setShowModal(true);}} >Create Group </button>
              </div>
              <div className="inf-widget4__item" style={{display:'block'}}>            
                <div className={"input-group"}>
                  <div className="input-group-prepend">
                    <span className="input-group-text inf-search__input_group_text">
                      <i className="flaticon2-search-1" />
                    </span>
                  </div>
                  <input
                    type="text"
                    autoFocus={true}
                    placeholder="Search..."
                    onChange={(event)=> {searchGroup(groupList, event.target.value);}}
                    className="form-control inf-search__input"
                  />
                </div>
                <Table dataSource={filterList} columns={columns} pagination locale={{
                  emptyText: <span> No group found </span>,
                }}/>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="row">
        {loading && (<Spinner />)}
      </div>
      <CreateGroupModal isOpen={showModal} onClose={handleCloseModal} group={{}} history={history} /> 
    </>
  );
}

const mapStateToProps = (state) => {
  const user = state.user;
  return {user }
}

const mapDispatchToProps = {
  listCognitoGroupApi,
};

export default connect(mapStateToProps, mapDispatchToProps)(GroupListPage);