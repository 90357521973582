import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { getFileContentApi } from "../../store/actions";
import { Skeleton } from "antd";
import { Image } from 'antd';

function ImagePreview(props) {
  const {s3_url, path} = props;
  const [fileLoading, setFileLoading ] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    if(s3_url) {
      setFileLoading(true);
      props.getFileContentApi(s3_url, true).then((result)=> {
        const options = path.toLowerCase().endsWith('.svg') ? { type: 'image/svg+xml' } : undefined;
        // @ts-expect-error TS(2345): Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        setImageUrl(URL.createObjectURL(new Blob([new Uint8Array(result.value)], options)));
        setFileLoading(false);
      })
    }
  }, [s3_url]);

  return (
    <>
      {fileLoading ? <Skeleton /> : <Image src={imageUrl} />}
    </>
  );
}

const mapDispatchToProps = {
  getFileContentApi,
};

export default connect(null, mapDispatchToProps)(ImagePreview);