import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { getUserRole, getExtension, IMAGE_EXTENSIONS, DATA_EXTENSIONS, TEXT_EXTENSIONS, PDF_EXTENSIONS } from "../utils/utils";
import { getFileContentApi, lookupOneFileByTimeApi } from "../store/actions";
import ImagePreview from "./preview/ImagePreview";
import TextPreview from "./preview/TextPreview";
import Spinner from "../layout/Spinner";
import PdfPreview from "./preview/PdfPreview";
import DataPreview from "./preview/DataPreview ";

function FilePreview(props) {
  const {bucket,selectedFile} = props;
  const [fileLoading, setFileLoading ] = useState(false);
  const [fileLoadingError, setFileLoadingError ] = useState(false);
  const [s3_url, setS3_url ] = useState(null);

  useEffect(() => {
    if(selectedFile.bucketname) {
      setFileLoading(true);
      const browseData = {
        op: "GETFILESTATUS",
        gets3url: true,
        bucket: selectedFile.bucketname,
        time: selectedFile.time,
      }
      if(selectedFile.timeBegin){
        browseData.timeBegin = selectedFile.timeBegin;
      }
      props.lookupOneFileByTimeApi(selectedFile.path, browseData).then(res => {
        const resultData = res.value;
        const { s3url } = resultData;
        if(s3url) {
          setS3_url(s3url);
        } else{
          // TODO handle Error 
          setFileLoadingError(true);
        }
        setFileLoading(false);
      }).catch(err=>  {
        setFileLoading(false);
        setFileLoadingError(true);
      });
    }
  }, [selectedFile]);

  const previewCode = () => {
    if(fileLoading) { 
      return(<Spinner />);
    } else if(fileLoadingError) {
      return (
        <div className="inf-noresult">
          <div>
            <div className="flaticon2-cancel-music inf-error-icon"> </div>
            <span className='inf-noresult-text'> 
              Failed to preview.
            </span>
          </div>          
        </div>
      )
    } else {
      if(selectedFile.bucketname) {
        const fileExtension = getExtension(selectedFile.path);
        if (fileExtension) {
          if (IMAGE_EXTENSIONS.has(fileExtension.toLowerCase())) {
            return <div><ImagePreview path={selectedFile.path} s3_url={s3_url}/> </div>;
          } else if (DATA_EXTENSIONS.has(fileExtension.toLowerCase())) {
            return <div><DataPreview path={selectedFile.path} s3_url={s3_url}/> </div>;
          } else if (PDF_EXTENSIONS.has(fileExtension.toLowerCase())) {
            return <div><PdfPreview path={selectedFile.path} s3_url={s3_url}/> </div>;
          } else if (TEXT_EXTENSIONS.has(fileExtension.toLowerCase())) {
            return <div><TextPreview path={selectedFile.path} s3_url={s3_url}/> </div>;
          } else {
            return <div className="inf-noresult">
              <div>
                <div className="flaticon2-warning inf-noresult-icon"> </div>
                <span className='inf-noresult-text' style={{display: 'flex', justifyContent: 'center'}}> 
                  .{fileExtension} file is not support to preview. 
                </span> <br />
                <span className='inf-noresult-text'> Supported formats: image, text, pdf, json, csv files </span>          
              </div>
            </div>
          }
        } else {
          return <div className="inf-noresult">
            <div>
              <div className="flaticon2-warning inf-noresult-icon"> </div>
              <span className='inf-noresult-text' style={{display: 'flex', justifyContent: 'center'}}> 
                .{fileExtension} file is not support to preview. 
              </span> <br />
              <span className='inf-noresult-text'> Supported formats: image, text, pdf, json, csv files </span>          
            </div>
          </div>
        }
      } else {
        return <div className="inf-noresult">
          <div>
            <div className="flaticon-imac inf-noresult-icon"> </div>
            <span className='inf-noresult-text' style={{display: 'flex', justifyContent: 'center'}}> 
              Select file to preview 
            </span>  <br />
            <span className='inf-noresult-text'> (image, text, pdf, json, csv, files only) </span>
          </div>          
        </div>
      }
    } 
  }

  return (
    <>
      <div className="inf-portlet inf-portlet--height-fluid">
        <div className="inf-portlet__body">
          <div className="inf-widget4">
            <div className="inf-widget4__item ">
              <div className="inf-widget4__info ">
                <span className="inf-widget4__username">Path: s3://{bucket.bucketname}{selectedFile.path && (selectedFile.path )}</span>
                {/* TODO : display file size */}
                {/* {selectedFile.path && (
                  <p className="inf-widget4__text "> size: - {size} </p>
                )} */}
              </div>
              {selectedFile.path && (
                <a href={s3_url} target="_blank" rel="noopener noreferrer" className="btn btn-sm btn-label-brand"> 
                  <i className=" flaticon-download-1"></i> Download
                </a>
              )}
            </div>
            <div>
              <div className="inf-file-preview">
                {previewCode()}                
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state, ownProps) => {
  const user = state.user;
  const userRole = getUserRole(user)
  return { user, userRole }
}

const mapDispatchToProps = {
  getFileContentApi,
  lookupOneFileByTimeApi
};

export default connect(mapStateToProps, mapDispatchToProps)(FilePreview);