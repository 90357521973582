import React, { Component } from 'react';
import { Modal } from 'antd';

/**
 * Generic modal that has a title and an input field with a save/submit button.
 */
export class InputModal extends Component {
  state = {
    isSubmitting: false,
  };

  formRef = React.createRef();

  onSubmit = async () => {
    this.setState({ isSubmitting: true });
    try {
      const values = await (this).formRef.current.validateFields();
      // call handleSubmit from parent component, pass form values
      // handleSubmit is expected to return a promise
      return await this.props
        .handleSubmit(values)
        .then(this.resetAndClearModalForm)
        .catch(this.handleSubmitFailure)
        .finally(this.onRequestCloseHandler);
    } catch (e) {
      this.setState({ isSubmitting: false });
      return Promise.reject(e);
    }
  };

  resetAndClearModalForm = () => {
    this.setState({ isSubmitting: false });
    (this).formRef.current.resetFields();
  };

  handleSubmitFailure = (e) => {
    this.setState({ isSubmitting: false });
  };

  onRequestCloseHandler = () => {
    if (!this.state.isSubmitting) {
      this.resetAndClearModalForm();
      this.props.onClose();
    }
  };

  handleCancel = () => {
    this.onRequestCloseHandler();
    // Check for optional `onCancel` method prop.
    if (this.props.onClose !== undefined) {
      this.props.onClose();
    }
  };

  render() {
    const { isSubmitting } = this.state;
    const { okText, cancelText, isOpen, footer, children } = this.props;

    // add props (ref) to passed component
    const displayForm = React.Children.map(children, (child) => {
      // Checking isValidElement is the safe way and avoids a typescript
      // error too.
      if (React.isValidElement(child)) {
        return React.cloneElement(child, { innerRef: this.formRef });
      }
      return child;
    });

    return (
      <Modal
        data-testid='input-modal'
        title={this.props.title}
        width={540}
        open={isOpen}
        onOk={this.onSubmit}
        okText={okText}
        cancelText={cancelText}
        confirmLoading={isSubmitting}
        onCancel={this.handleCancel}
        footer={footer}
        centered
      >
        {displayForm}
      </Modal>
    );
  }
}
